import React from 'react';
import { Link } from 'react-router-dom'; // Importa Link si usas react-router-dom
import "./styles.css";
import banner1 from "./image.png";
import CustomSlider from '../../Packages/DetailPackages/sliderImg/CustomSlider';

const BannerCarousel = () => {

  // Añadir título, descripción y ruta para cada banner
  const banners = [
    { img: banner1, welcome: "Bienvenido", title: "DR. JOSE <br/> RODRIGUEZ", description: "Especialista en cirugía, estética y reconstructiva", link: "/profile/1" },
    { img: banner1, welcome: "Bienvenido", title: "DR. JOSE <br/>  RODRIGUEZ", description: "Especialista en cirugía, estética y reconstructiva", link: "/profile/2" },
    { img: banner1, welcome: "Bienvenido", title: "DR. JOSE <br/>  RODRIGUEZ", description: "Especialista en cirugía, estética y reconstructiva", link: "/profile/3" },
    { img: banner1, welcome: "Bienvenido", title: "DR. JOSE <br/>  RODRIGUEZ", description: "Especialista en cirugía, estética y reconstructiva", link: "/profile/4" },
    { img: banner1, welcome: "Bienvenido", title: "DR. JOSE <br/>  RODRIGUEZ", description: "Especialista en cirugía, estética y reconstructiva", link: "/profile/5" }
  ];

  return (
    <div className='banner-carousel-container'>
      <CustomSlider width={"100%"}>
        {
          banners.map((banner, index) => (
            <div className='banner-item' key={index} style={{ position: 'relative', width: '100%', height: '100%' }}>
              <img 
                src={banner.img}
                alt={`Banner ${index + 1}`}
                style={{  maxHeight: '460px', width: '100%' }}
              />
              <div className='banner-content'>
                <span>{banner.welcome}</span>
                <h2 className='banner-title' dangerouslySetInnerHTML={{ __html: banner.title }} />
                <p className='banner-description'>{banner.description}</p>
              </div>

              {/* <Link to={banner.link} className='view-profile-button'>Ver Perfil</Link> */}
            </div>
          ))
        }
      </CustomSlider>
    </div>
  );
};

export default BannerCarousel;
