import useUserProfile from "../../viewModels/profile/user.profile.viewModel";
import SvgIcon, { iconList } from "../share/components/iconSVG";
import "./styles.css";
import AnyQuestions from "../home/components/anyQuestion";
import CommercialProfile from "./components/sections/commercialProfile";
import MyCalendar from "./components/sections/myCalendar";
import MyTravels from "./components/sections/myTravels";
import AppointmentRequests from "./components/sections/appointmentRequests";
import TeleConsultation from "./components/sections/appointmentRequests/teleConsultation";
import MyMovements from "./components/sections/myMovements";
import { RiLogoutCircleLine } from "react-icons/ri";
import { IoNotifications, IoToggleSharp } from "react-icons/io5";
import MyNotifications from "./components/sections/myNotifications";
import MyCart from "./components/sections/Cart";
import { MdEdit } from "react-icons/md";
import ModalContainer from "../share/components/modalContainer";
import { useState } from "react";
import useUserUpdate from "@/ui/viewModels/session/update.user.viewModel";
import UserUpdate from "./components/Update/UserUpdate";

const formatDate = (dateString) => {
  const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${day} de ${months[month]} del ${year}`;
};


const FeatureUnderDevelopment = () => {
  return (
    <div className="functionalityUnderDevelopment">
      <SvgIcon
        name={iconList.IconAlertOutLine}
        size={60}
        color={"var(--bluePrimary)"}
      />
      <p className="functionalityUnderDevelopmentText">
        Funcionalidad en desarrollo
      </p>
    </div>
  );
};

function ProfileUser() {
  const {
    userDetail,
    notifications,
    notificationsSelecteds,
    optionsList,
    optionActive,
    setOptionActive,
    selectOrDeselectNotification,
    isSelectedThisNotification,
    newNotifications,
    configProfile,
    switchOptionConfig,
    closeSession,
    goToTeleConsultation,
    codeTeleconsutation,
    goToMyMovements,
    codeMyMovements,
    goToMyNotifications,
    codeMyNotifications
  } = useUserProfile();

  const notificationsItems = [
    {
      type: "flight",
      message: "Tu vuelo a París ha sido confirmado",
      time: "Hace 2 horas",
    },
    {
      type: "hotel",
      message: "Tu reserva en el Hotel Ritz ha sido actualizada",
      time: "Ayer",
    },
    {
      type: "transfer",
      message: "Tu traslado desde el aeropuerto está en camino",
      time: "Hace 15 minutos",
    },
    {
      type: "flight",
      message: "Tu vuelo a Nueva York ha sido retrasado",
      time: "Hace 30 minutos",
    },
    {
      type: "hotel",
      message: "Tu habitación en el Hotel Hilton está lista ",
      time: "Justo ahora",
    },
  ];

  const [show, setShow] = useState(false)

  console.log("User Detail: ", userDetail)

  return (
    <div className="profileScreen">
      <div className="profileScreenContent">
        <div className="profileScreen_Header">
          <h1>Wellezy personas</h1>
        </div>
        <div className="profileScreen_Container">
          <div className="profileScreen_ContainerSideLeft">
            <div className="profileScreen_ColumnContainer">
              {optionsList.length > 0 &&
                optionsList.map((index, key) => {
                  const isActive = optionActive === index.value;
                  return (
                    <div
                      key={key}
                      onClick={() => setOptionActive(index?.value)}
                      className={`optionProfile ${isActive ? "optionProfileActive" : ""
                        }`}
                    >
                      <div
                        className={`${isActive
                          ? "optionProfileImageContainer active"
                          : "optionProfileImageContainer"
                          }`}
                      >
                        <SvgIcon
                          name={index?.icon}
                          size={30}
                          color={isActive ? "var(--white)" : "var(--blackGray)"}
                        />
                      </div>

                      <p
                        className={`${isActive
                          ? "optionProfileText active"
                          : "optionProfileText"
                          }`}
                      >
                        {index?.text}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="logOutButtonProfile">
              <div className="optionProfile" onClick={closeSession}>
                <div className="optionProfileImageContainer">
                  <RiLogoutCircleLine size={30} color="var(--blackGray)" />
                </div>

                <p className="optionProfileText">Cerrar sesión</p>
              </div>
            </div>
          </div>
          {optionActive === 1 && (
            <div className="bigContainer">
              <div className="containerProfileNotifications">
                <div className="profileScreen_CardShadow profileCard">
                  <div className="profileCardHeader">
                    <div className="profileCardHeaderAvatar">
                      <div className="profileCardHeaderAvatarWrapper">
                        <img
                          src={userDetail?.profile_photo_url}
                          alt="profile-avatar"
                          className="profileCardHeaderAvatarWrapperImg"
                        />
                      </div>

                      <button onClick={() => setShow(true)} className="profileCardHeaderInfoTextSmall">
                        <MdEdit size={25} />
                      </button>
                    </div>
                    <div className="profileCardHeaderInfo">
                      <p className="profileCardHeaderInfoTextBig">
                        <span>Bienvenido,</span>
                        {userDetail?.name} {userDetail?.lastname}
                      </p>

                      <div className="profileCardBody">
                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            Nombres y apellidos
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.name}
                          </p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            Fecha de nacimiento
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.birth ? formatDate(userDetail.birth) : 'Sin Asignar'}
                          </p> 
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            Genero
                          </p>
                          <p className="profileCardBodyGroupText">{userDetail?.gener || 'Sin Asignar'}</p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            Email
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.email}
                          </p>
                        </div>

                        <div className="profileCardBodyGroup">
                          <p className="profileCardBodyGroupText profileCardBodyGroupTextBig">
                            Celular
                          </p>
                          <p className="profileCardBodyGroupText">
                            {userDetail?.prex_phone_public} {userDetail?.phone_public}
                          </p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="containerProfileNotifications">
                <div className="containerProfileNotificationsHeader">
                  <h3>Notificaciones</h3>
                  <button>Ver más</button>
                </div>

                <div className="containerProfileNotificationsItems">
                  {notificationsItems.map((notification, index) => (
                    <div
                      key={index}
                      className="containerProfileNotificationsItem"
                    >
                      <div className="containerProfileNotificationsItemIcon">
                        <IoNotifications size={15} />
                      </div>
                      <div className="containerProfileNotificationsItemContent">
                        {notification.message}
                        <p>{notification.time}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="containerProfileNotificationsFooter">
                  <p>¿Quiero recibir notificaciones <br /> a mi correo eléctronico?</p>
                  <IoToggleSharp size={30} color="var(--bluePrimary)" />
                </div>
              </div>
            </div>
          )}

          <ModalContainer width={500} show={show} hiddsetActiveChangeServiceen={setShow}>
            <UserUpdate show={show} />
          </ModalContainer>
          {optionActive === 2 && <CommercialProfile />}
          {optionActive === 3 && <MyMovements code={codeMyMovements} />}
          {optionActive === 5 && <AppointmentRequests />}
          {optionActive === 6 && (
            <TeleConsultation code={codeTeleconsutation} />
          )}
          {optionActive === 7 && <FeatureUnderDevelopment />}
          {optionActive === 8 && <FeatureUnderDevelopment />}
          {optionActive === 9 && <FeatureUnderDevelopment />}
          {optionActive === 11 && <MyTravels />}
          {optionActive === 12 && <FeatureUnderDevelopment />}
          {optionActive === 13 && <FeatureUnderDevelopment />}
          {optionActive === 14 && <MyNotifications code={codeMyNotifications} />}
          {optionActive === 15 && <MyCart />}
        </div>
      </div>

      <AnyQuestions title="¿Tienes alguna duda?" />
    </div>
  );
}
export default ProfileUser;
