import React from "react";
import PropTypes from "prop-types";
import Modal from "common/components/Modal";

// TODO: include translations for the text of the modal
const ConfirmModal = ({ showConfirmModal, setShowConfirmModal, confirmProcedure, hasCloseButton }) => {
  return (
    <Modal
      showModal={showConfirmModal}
      onClose={() => setShowConfirmModal(false)}
      hasCloseButton={hasCloseButton}
    >
      <div className="p-2 flex flex-col items-center justify-center">
        <h2 className="!text-base font-bold mb-4 text-center text-customBlue">
          ¿Estás seguro que deseas continuar?
        </h2>
        <p className="p-4 mb-6 text-center">
          Esto te llevará a rellenar el formulario de STD <br />
          <span className="font-semibold text-customBlue">
            (Sistema de Triage y Diagnóstico)
          </span>
        </p>
        <div className="flex justify-center mt-4 gap-5">
          <button
            onClick={() => setShowConfirmModal(false)}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            onClick={confirmProcedure}
            className="px-4 py-2 bg-customBlue text-white rounded hover:bg-customHoverBlue"
          >
            Confirmar
          </button>
        </div>
      </div>
    </Modal>
  );
};

Modal.propTypes = {
  showConfirmModal: PropTypes.bool.isRequired,
  setShowConfirmModal: PropTypes.func.isRequired,
  confirmProcedure: PropTypes.func.isRequired,
  hasCloseButton: PropTypes.bool,
}

Modal.defaultProps = {
  hasCloseButton: true,
}

export default ConfirmModal;