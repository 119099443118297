import styles from "./styles.module.css"
import { currencyFormat } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import TermAndConditions from "../../../../share/components/term&conditions/TermAndConditions";
import connectMiles from "../../../../../assets/logos/connectmiles.png";
import Loading from "../../../../share/components/loading/index";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import CarouselSevices from "../../../../share/components/CarouselServices/CarouselSevices";
import ReturnPage from "../../../../share/components/return/ReturnPage";
import AnyQuestions from "../../../../home/components/anyQuestion";
import Collapsible from "@/ui/views/BuildPackage/pages/std/components/Collapsible/Collapsible";
import FlightTimer from "../FlightTimer/FlightTimer";
import { MdFlight } from "react-icons/md";

const Step3 = () => {
  const { state } = useLocation();
  const porcentaje = 0.05;
  const subTotal = state?.priceWithOutTaxes + state?.priceTaxes;
  const otrosCargos = subTotal * porcentaje;
  const total = subTotal + otrosCargos;
  const [t] = useTranslation("global");
  const [loadingLink, setLoadingLink] = useState(false);

  console.log(state?.priceWithOutTaxes);


  function getBaseUrl(url) {
    const parsedUrl = new URL(url);
    const baseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;
    return baseUrl;
  }
  const typeOfCoin = { name: state?.currency, abbreviation: state?.currency, simbol: '$' };


  const createLinkPay = () => {
    const dataNew = {
      localizator: state?.controlNumberPNR,
      type: "Amadeus",
      price: total,
      url: `${getBaseUrl(window.location.href)}/flight/flightsPay`,
      currency: state?.currency
    };
    const url = process.env.REACT_APP_URL_CREATE_LINK;
    setLoadingLink(true);
    axios
      .post(url, dataNew)
      .then((res) => {
        localStorage.setItem("data-pay-flight", JSON.stringify(state));
        localStorage.setItem("request-id-pay", res.data?.requestId);
        window.location.href = res.data?.processUrl;
      })
      .catch((err) => {
        console.log(err);
        setLoadingLink(false);
      });
  };

  const questtions = [
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
    {
      question: "¿Como tener informacion de mi vuelo?",
      info: "Te llegara info al correo con los ticketes de abordaje",
    },
  ];

  const [confirm, setConfirm] = useState(false);

  const [isRoundTripData, setIsRoundTripData] = useState(false)

  useEffect(() => {
    if (state?.requestPNR?.itinerary[1]) {
      setIsRoundTripData(true)
    }
  }, [])

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Obtener las partes individuales de la fecha
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0
    const year = date.getFullYear();

    // Obtener las partes de la hora
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Formato final
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    // Obtener las partes de la hora
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Formato final de la hora
    return `${hours}:${minutes}`;
  };


  const getPaymentDeadline = () => {
    const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'];
    const monthsOfYear = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sept', 'oct', 'nov', 'dic'];

    const now = new Date();

    // Sumar 10 minutos
    now.setMinutes(now.getMinutes() + 20);

    // Obtener día de la semana, día del mes, mes y año
    const dayOfWeek = daysOfWeek[now.getDay()];
    const day = now.getDate().toString().padStart(2, '0');
    const month = monthsOfYear[now.getMonth()];
    const year = now.getFullYear();

    // Obtener hora y minutos
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');

    // Formato final
    return `${dayOfWeek} ${day} ${month} ${year}, ${hours}:${minutes}hrs`;
  };

  const uniqueTravellers = state?.requestPNR?.travellers?.filter((traveller, index, self) =>
    index === self.findIndex((t) => t.document === traveller.document)
  );


  return (
    <>
      {loadingLink && <Loading size={80} />}
      <CarouselSevices current={t("carousel_services.fligth")} />
      <ReturnPage />
      <div className={styles.methodPayContainer}>
        <div className={styles.methodPayHeader}>
          <h3>Paga a tiempo y asegura tu viaje</h3>
          <p>Realiza el pago antes de que el tiempo se termine</p>
        </div>
        <div className={styles.methodPayContain}>

          <div className={styles.methodPayContainInfo}>
            <div className={styles.methodPayImageContain}>
              <div className={styles.methodPayContainInfoContain}>
                <h3>Detalles de reservacion</h3>

                <div className={styles.methodPayContainInfoContainAll}>
                  <div className={styles.methodPayContainInfoContainAllInfoCount}>

                  <div className={styles.methodPayContainQuestions}>
                      <FlightTimer />
                    </div>
                    <div className={styles.infoContainItem}>
                      <label>Titular de la reservacion:</label>
                      <p>{state?.requestPNR?.travellers[0]?.name || "Usuario"} {state?.requestPNR?.travellers[0]?.lastName
                        || "Usuario"}</p>
                    </div>
                    <div className={styles.infoContainItem}>
                      <label>Pasajeros:</label>
                      <ul>
                        {uniqueTravellers?.length > 0 ? uniqueTravellers.map((passenger, index) => (
                          <li key={index}>
                            <span>{passenger.passengerType}</span> - {passenger.name} {passenger.lastName}
                          </li>
                        )) : (
                          <li>No hay pasajeros</li>
                        )}
                      </ul>
                    </div>

                    <div className={styles.infoContainItem}>
                      <label>Fecha limite para pagar</label>
                      <p>{getPaymentDeadline()}</p>
                    </div>

                    

                    <Link className={styles.termsAndconditions} to="/terms&conditions">Términos y condiciones</Link>
                  </div>

                  <div className={styles.itemDetailsFlightsAll}>
                    <div className={styles.itemDetailsFlights}>
                      {/* Sección de IDA */}
                      <div className={styles.itemDetailsFlights1}>
                        <div className={styles.itemDetailsFlightsColumn1}>
                          <p className={styles.typeFlight}>
                            <strong>IDA</strong> {formatDate(state?.requestPNR?.itinerary[0]?.dateDeparture)}
                          </p>
                        </div>

                        <div className={styles.airlineLogo}>
                          <img
                            src={`https://pics.avs.io/60/60/${state?.requestPNR?.itinerary[0]?.company}.png`}
                            alt={`${state?.requestPNR?.itinerary[0]?.company} logo`}
                          />
                        </div>

                        <div className={styles.itemDetailsFlightsColumn1Footer}>
                          <div className={styles.itemDetailsFlightsColumn1FooterPlace}>
                            <p>{state?.requestPNR?.itinerary[0]?.from}</p>
                            <b>{formatTime(state?.requestPNR?.itinerary[0]?.dateDeparture)}</b>
                          </div>
                          <p>Directo</p>
                          <div className={styles.itemDetailsFlightsColumn1FooterPlace}>
                            <p>{state?.requestPNR?.itinerary[0]?.to}</p>
                            <b>{formatTime(state?.requestPNR?.itinerary[0]?.dateArrival)}</b>
                          </div>
                        </div>
                      </div>

                      {/* Sección de VUELTA (solo si existe) */}
                      {isRoundTripData && (
                        <div className={styles.itemDetailsFlights2}>
                          <div className={styles.itemDetailsFlightsColumn1}>
                            <p className={styles.typeFlight}>
                              <strong>VUELTA</strong> {formatDate(state?.requestPNR?.itinerary[1]?.dateDeparture)}
                            </p>
                          </div>

                          <div className={styles.airlineLogo}>
                            <img
                              src={`https://pics.avs.io/60/60/${state?.requestPNR?.itinerary[1]?.company}.png`}
                              alt={`${state?.requestPNR?.itinerary[1]?.company} logo`}
                            />
                          </div>

                          <div className={styles.itemDetailsFlightsColumn1Footer}>
                            <div
                              className={styles.itemDetailsFlightsColumn1FooterPlace}
                            >
                              <p>{state?.requestPNR?.itinerary[1]?.from} </p>
                              <b>{formatTime(state?.requestPNR?.itinerary[1]?.dateDeparture)}</b>
                            </div>
                            <p>Directo</p>
                            <div
                              className={styles.itemDetailsFlightsColumn1FooterPlace}
                            >
                              <p>{state?.requestPNR?.itinerary[1]?.to}</p>
                              <b>{formatTime(state?.requestPNR?.itinerary[1]?.dateArrival)}</b>
                            </div>
                          </div>
                        </div>
                      )}

                      {!isRoundTripData && (
                        <div className={styles.itemDetailsFlights2Any}>
                          No hay vuelo de vuelta
                          <MdFlight size={20} />
                        </div>
                      )}
                    </div>


                    <div className={styles.methodValues}>

                      <div className={styles.methodValuesContaint}>
                        <div className={styles.methodValuesItem}>
                          <h4>{t("fligth.form_passenger.step3.sub_total")}:</h4>
                          <span>$ {currencyFormat(subTotal, typeOfCoin)?.total}</span>
                        </div>

                        <div className={styles.methodValuesItem}>
                          <h4> {t("fligth.form_passenger.step3.other_taxes")}:</h4>
                          <span>$ {currencyFormat(otrosCargos, typeOfCoin)?.total}</span>
                        </div>

                        <div className={styles.methodValuesItemTotal}>
                          <h4>{t("fligth.form_passenger.step3.total")}:</h4>
                          <span>$ {currencyFormat(total, typeOfCoin)?.total}</span>
                        </div>
                      </div>

                      <div className={styles.methodValuesButtons}>
                        <div className={styles.methodValuesLabel}>
                          <input
                            type="checkbox"
                            className={styles.uiCheckbox}
                            name="confirm"
                            id="confirm"
                            onChange={() => setConfirm(!confirm)}
                          />
                          <label htmlFor="confirm">Confirmar compra</label>
                        </div>

                        <button
                          className={`${styles.methodPayButton} ${!confirm ? styles.disabled : ''}`}
                          onClick={() => createLinkPay()}
                          disabled={!confirm}
                        >
                          Pagar ahora
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.methodPayImageContainImage}>
                <img
                  src="https://static.placetopay.com/placetopay-logo.svg"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  )
}

export default Step3