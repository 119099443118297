import { useRef, useState, useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { iconList } from "../iconSVG";
import ModelCarousel from "./ModelCarousel.jsx";
import SvgIcon from "../iconSVG";
import { get, keys } from "../../../../localStorage";
import { widgetsList } from "../../../../../infra/constants/app";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DisabledModal from "../disabledModal/disabledModal.jsx";
import TextComponent from "../fonts/TextComponent";

const MotionConstraints = styled(motion.div)`
  overflow-x: hidden;
`;
const MotionBox = styled(motion.div)`
  width: 100vw;
`;

export const { servicesTravel } = ModelCarousel();

const CarouselSevices = ({ current = "/" }) => {
  const [t] = useTranslation("global");
  const [showOnlyThis, setShowOnlyThis] = useState([]);

  const [scrollPosition, setScrollPosition] = useState("0px");
  const controls = useAnimation();
  const carouselRef = useRef();

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      controls.start({ x: savedScrollPosition });
      setScrollPosition(savedScrollPosition);
    }
  }, [controls]);

  function addWidgetURL(url) {
    const isWidget = widgetsList.find(
      (w) => w.path === window.location.pathname
    );
    return isWidget ? `/widget${url}` : url;
  }

  const servicesTravel = [
    {
      id: "home",
      title: t("carousel_services.home"),
      img: iconList.IconHome,
      route: addWidgetURL("/"),
      disable: true,
    },
    {
      id: "fligths",
      title: t("carousel_services.fligth"),
      img: iconList.IconPlane,
      route: addWidgetURL("/fligths"),
      disable: true,
    },
    {
      id: "hotel",
      title: t("carousel_services.hotel"),
      img: iconList.IconHotel,
      route: addWidgetURL("/hotel"),
      disable: true,
    },
    {
      id: "tours",
      title: t("carousel_services.tours"),
      img: iconList.IconBag,
      route: addWidgetURL("/tours"),
      disable: true,
    },
    {
      id: "transport",
      title: t("carousel_services.transfers"),
      img: iconList.IconTaxi,
      route: addWidgetURL("/transport"),
      disable: true,
    },
    {
      id: "carRentals",
      title: t("carousel_services.car_rental"),
      img: iconList.IconRentaCar,
      route: addWidgetURL("/carRentals"),
      disable: false,
    },
    {
      id: "packages",
      title: t("carousel_services.packages"),
      img: iconList.IconTravel,
      route: addWidgetURL("/packages"),
      disable: true,
    },
    {
      id: "travelSecure",
      title: t("carousel_services.travel_secure"),
      img: iconList.IconSecureTravel,
      route: addWidgetURL("/travelSecure"),
      disable: true,
    },
    {
      id: "specialists",
      title: t("carousel_services.specialist"),
      img: iconList.IconDoctor,
      route: addWidgetURL("/Specialists"),
      disable: true,
    },
    {
      id: "clinics",
      title: t("carousel_services.clinics"),
      img: iconList.IconHospital,
      route: addWidgetURL("/Clinics"),
      disable: true,
    },
    {
      id: "healthWellness",
      title: t("carousel_services.health_wellness"),
      img: iconList.IconBeautiful,
      route: addWidgetURL("/healthWellness"),
      disable: false,
    },
    {
      id: "ambulance",
      title: t("carousel_services.ambulance"),
      img: iconList.IconAmbulance,
      route: addWidgetURL("/ambulance"),
      disable: false,
    },
    {
      id: "pharmacy",
      title: t("carousel_services.pharmacy"),
      img: iconList.IconMedicaments,
      route: addWidgetURL("/Pharmacy"),
      disable: false,
    },
    {
      id: "cosmetics",
      title: t("carousel_services.cosmetics"),
      img: iconList.IconDoctor,
      route: addWidgetURL("/Cosmetics"),
      disable: false,
    },
    {
      id: "recoveryHouse",
      title: t("carousel_services.recovery_house"),
      img: iconList.IconRestHouse,
      route: addWidgetURL("/RecoveryHouse"),
      disable: false,
    },
  ];

  const constraintsRef = useRef(null);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (constraintsRef.current) {
      const width = constraintsRef.current.offsetWidth;
    }
  }, []);

  const scrollLeft = () => {
    if (carouselRef.current) {
      const newPosition = carouselRef.current.scrollLeft - 265; // Ajusta el valor de desplazamiento según tu diseño
      animateScroll(newPosition);
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      const newPosition = carouselRef.current.scrollLeft + 265; // Ajusta el valor de desplazamiento según tu diseño
      animateScroll(newPosition);
    }
  };

  const animateScroll = (newPosition) => {
    if (!isAnimating && carouselRef.current) {
      setIsAnimating(true);
      carouselRef.current.scrollTo({
        left: newPosition,
        behavior: "smooth", // Opcional, para un desplazamiento suave
      });

      // Después de que termine la animación, verifica si se llegó al final
      setTimeout(() => {
        setIsAnimating(false);
        checkBoundaries();
      }, 500); // Ajusta el tiempo de espera según la duración de tu animación
    }
  };

  const checkBoundaries = () => {
    if (carouselRef.current) {
      const container = carouselRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const currentPosition = container.scrollLeft;

      // Si está en el borde izquierdo o derecho, añade una animación de rebote
      if (currentPosition === 0 || currentPosition === maxScrollLeft) {
        container.scrollTo({
          left: currentPosition + (currentPosition === 0 ? -20 : 20), // Ajusta la distancia del rebote
          behavior: "smooth",
        });
      }
    }
  };

  // Filtramos la lista de servicios para mostrar solo los que están en showOnlyThis (si existe y tiene elementos)
  const servicesTravelList =
    showOnlyThis && showOnlyThis.length > 0 // Verificamos si showOnlyThis existe y no está vacío
      ? servicesTravel.filter((route) => showOnlyThis.includes(route.id))
      // Si showOnlyThis tiene elementos, filtramos servicesTravel para incluir solo los elementos cuyo id está en showOnlyThis
      : servicesTravel;
  // Si showOnlyThis no existe o está vacío, usamos la lista completa de servicesTravel

  // Verificamos si la ruta actual de la ventana comienza con "/flight/"
  const isFlightRouteSelected = window.location.pathname.startsWith("/flight/");
  // console.log("window.location.pathname:", window.location.pathname);
  //console.log("isFlightRouteSelected:", isFlightRouteSelected);


  async function getServicesList() {
    try {
      const response = await get(keys?.servicesMenu);
      setShowOnlyThis(response);
    } catch (error) {
      console.log("Error in getServicesList(): ", error);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getServicesList();
    }, 100);
  }, []);

  return (
    <section className="services-travel">
      <button className="buttonArrow" onClick={scrollLeft}>
        <IoIosArrowBack size={20} />
      </button>
      <div style={{ overflowX: "scroll", display: "flex" }} ref={carouselRef}>
        {/* Asumiendo que MotionConstraints no es esencial para tu diseño */}
        <motion.div className="slider_" ref={constraintsRef}>
          {servicesTravelList.map((item, key) => (
            // Mapeamos cada item en servicesTravelList para crear el elemento visual
            <motion.div className="div_homeTurism" key={key}>
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={2}
                className="grid_cardHomeTurism"
              >
                <ul>
                  <li className="list-carrousel-verticales">
                    <DisabledModal item={item}>
                      <div
                        style={{
                          // Determinamos el color de fondo dependiendo de si el item actual está seleccionado
                          backgroundColor: (current === item.title || current === item.route || (isFlightRouteSelected && item.id === "fligths"))
                            ? "#00AFE8" // Si está seleccionado, color azul
                            : "transparent", // Si no, transparente
                          borderRadius: "50%", // Redondeamos los bordes al 50% para hacer un círculo
                          color: (current === item.title || current === item.route || (isFlightRouteSelected && item.id === "fligths"))
                            ? "#00AFE8" // Color del texto si está seleccionado, azul
                            : "#004274", // Color del texto si no está seleccionado, azul oscuro
                          padding: current === "travelSecure" || current === "healthWellness"
                            ? "5px 0px 0px 0px" // Padding especial para algunos servicios específicos
                            : "4px 5px 0px 2px", // Padding estándar para otros
                          width: current === "travelSecure" || current === "healthWellness"
                            ? "65px" // Ancho especial para algunos servicios específicos
                            : "55px", // Ancho estándar para otros
                          marginBottom: "5px", // Espacio abajo del ícono
                        }}
                      >
                        <SvgIcon
                          color={(current === item.title || current === item.route || (isFlightRouteSelected && item.id === "fligths"))
                            ? "#fff" // Color del ícono si está seleccionado, blanco
                            : "#004274"} // Color del ícono si no está seleccionado, azul oscuro
                          size={70} // Tamaño del ícono
                          name={item?.img} // Nombre del ícono desde item.img
                        />
                      </div>
                      <TextComponent
                        size={"min"} // Tamaño del texto mínimo
                        children={item.title} // Texto que se muestra, el título del item
                        color={(current === item.title || current === item.route || (isFlightRouteSelected && item.id === "fligths"))
                          ? "#00AFE8" // Color del texto si está seleccionado, azul
                          : "#004274"} // Color del texto si no está seleccionado, azul oscuro
                      />
                    </DisabledModal>
                  </li>
                </ul>
              </Grid>
            </motion.div>
          ))}
        </motion.div>
      </div>
      <button className="buttonArrow" onClick={scrollRight}>
        <IoIosArrowForward size={20} />
      </button>
    </section>
  );
};

export default CarouselSevices;
