import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { RiFilterFill } from "react-icons/ri";
import CarouselSevices, {
  servicesTravel,
} from "../share/components/CarouselServices/CarouselSevices";
import BannerClinics from "./bannerClinics/BannerClinics";
import ButtonsFilters from "./buttonsFilters/ButtonsFilters";
import UseClinics from "@/Hooks/BuildPackage/useClinic/UseClinics";
import AllClinics from "./AllClinics/AllClinics";
import { useTranslation } from "react-i18next";
import AnyQuestions from "../../../ui/views/home/components/anyQuestion";
import Paginado from "../Specialist/Paginado/Paginado";
import FilterSpecialist from "../Specialist/FilterSpecialist/FilterSpecialist";
import styles from "./styles.module.css"
import ModalContainer from "../share/components/modalContainer";
import ReturnPage from "../share/components/return/ReturnPage";

const Clinics = () => {
  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const [modalFilter, setModalFilter] = useState(false)

  const {
    dataClinics,
    urlImage,
    currentPage,
    nextHandler,
    prevHandler,
    mostrarNext,
    services,
    loading,
    personalizeFind,
    cityFind,
    filterClinics,
    detailClinics,
    setCurrentPage,
    findClinics,
    setCity,
    setName,
    city,
    name,
    isBuildPack,
  } = UseClinics();

  const isStepThree = location.pathname === "/build-package/clinic"; 

  return (
    <>
      <ModalContainer
        show={modalFilter}
        setHidden={setModalFilter}
        hiddsetActiveChangeServiceen={setModalFilter}
        sizeHeight={52}
      >
        <FilterSpecialist findSpecialist={findClinics} city={city} name={name} setCity={setCity} setName={setName} setModal={setModalFilter} seeFilter={isStepThree} filteredClinics={dataClinics} />
      </ModalContainer>

      {!isStepThree && (
        <CarouselSevices current={t("carousel_services.clinics")} />
      )}
      <div className={isStepThree ? styles.layout_specialist_step : styles.layout_specialist}>
        <div className={styles.filterBtn}>
          <button onClick={() => { setModalFilter(!modalFilter) }}> <RiFilterFill size={20} /> Filtrar Clinicas</button>
        </div>

        <div className={styles.filters}>
          <FilterSpecialist
            findSpecialist={findClinics}
            city={city}
            name={name}
            setCity={setCity}
            setName={setName}
            setModal={setModalFilter}
            seeFilter={isStepThree}
            inBuild={isStepThree} // Añadimos este prop basado en isStepThree
            filteredClinics={dataClinics}
          /> 
        </div>

        <div className={styles.results}>
          <AllClinics
            dataClinics={dataClinics}
            inBuild={isStepThree} 
            urlImage={urlImage}
            loading={loading}
            detailClinics={detailClinics}
            seeButton={isBuildPack}
          />
        </div>
      </div>


      <Paginado
        currentPage={services?.current_page ? services?.current_page : 1}
        totalPages={services?.last_page ? services?.last_page : 1}
        nextHandler={nextHandler}
        prevHandler={prevHandler}
        pageRangeDisplayed={5}
        setCurrentPage={setCurrentPage}
      />

      {!isStepThree && <AnyQuestions title={t("browse_home.have_doubt")} />}
    </>
  );
};

export default Clinics;
