import React from "react";
import "./styles.css";
import {
  FaUserMd,
  FaRegSmile,
  FaUserNurse,
  FaRegEye,
  FaFemale,
  FaProcedures,
  FaRegHeart,
  FaRegHandPaper,
  FaFire,
  FaBrain,
  FaHeartbeat,
} from "react-icons/fa";
import { Grid } from "@mui/material";
import { LiaToothSolid } from "react-icons/lia";
import { MdOutlineFace4 } from "react-icons/md";
import { GiBoxCutter, GiBrokenBone, GiCoffeeBeans, GiFemaleLegs, GiFetus, GiKidneys } from "react-icons/gi";
import { PiBone } from "react-icons/pi";
import { FaBacteria } from "react-icons/fa6";
import { IoWomanOutline } from "react-icons/io5";
import { GrUserFemale } from "react-icons/gr";
import { SiPicardsurgeles } from "react-icons/si";

const Specialities = () => {
  const specialties = [
    { name: "Cirugía Estética", icon: <GiBoxCutter /> },
    { name: "Odontología", icon: <LiaToothSolid /> },
    { name: "Antienvejecimiento", icon: <MdOutlineFace4 /> },
    { name: "Fertilidad", icon: <GiFetus /> },
    { name: "Células madres", icon: <SiPicardsurgeles /> },
    { name: "Cuidado de ojos", icon: <FaRegEye /> },
    { name: "Cirugía Bariátrica / Obesidad", icon: <IoWomanOutline /> },
    { name: "Ortopedia", icon: <GiBrokenBone /> },
    { name: "Ginecología", icon: <GiFemaleLegs /> },
    { name: "Urología", icon: <GiKidneys /> },
    { name: "Oncología", icon: <FaBacteria /> },
  ];

  return (
    <div className="content-specialties-turism-health">
      <h2>Nuestras Especialidades</h2>
      <div>
        <Grid container mb={-2} mt={'5px'}>
          <Grid item xs={12}>
            <Grid container>
              {specialties.map((specialist, index) => (
                <Grid item xs={12} md={6} lg={3} key={index} className="card-element">
                  <div>
                    <span>
                      {specialist.icon}
                    </span>
                    <p>{specialist.name}</p>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>

    </div>
  );
};

export default Specialities;
