import React, { useEffect } from "react";
import CarouselSevices, {
  servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";
import "./styles.css";
// import usePassengerInformationForm from "../../../viewModels/flights/passenger.information.form.viewModel";
import ModalContainer from "../../share/components/modalContainer";
import UseMakeReservation from "../../../viewModels/hotels/UseMakeReservation";
// import { useTranslation } from "react-i18next";
import PersonForm from "./PersonForm/PersonForm";
import SendInvoices from "./SendInvoce/SendInvoices";
import DetailPay from "./detailPay/DetailPay";
import DetailBuy from "./detailBuy copy/DetailBuy";
import Loading from "../../share/components/loading";
import { FaCircleCheck } from "react-icons/fa6";
import TextComponent from "../../share/components/fonts/TextComponent";
import ReturnPage from "../../share/components/return/ReturnPage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setDate } from "date-fns";
import AnyQuestions from "../../home/components/anyQuestion";
import Recapchat from "../../share/components/recapchat/Recapchat";
import UseRecapchat from "@/Hooks/useRecapchat/UseRecapchat";

const MakeReservation = () => {
  // const { needLogin, goToScreen, hiddenModalSesion } =
  //   usePassengerInformationForm();

  const {
    verifyCaptcha,
    onChangeCaptcha,
    siteKey
  } = UseRecapchat()

  const {
    paxes,
    setPaxes,
    cantAcomodationAdult,
    createReservation,
    state,
    cantAcomodationChild,
    errorReservation,
    setErrorReservation,
    loadingReservation,
    setLoadingReservation,
    loadingRespose,
    reservation,
    functionNavigatePaceToPay,
    verifyCaptchaErr
  } = UseMakeReservation(verifyCaptcha);



  const [t] = useTranslation("global");

  const persons = [];

  const mountRoom = JSON.parse(localStorage.getItem("mountRoom"));

  const [errorForm, setErrorForm] = useState({});
  const [validate, setValidate] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [accordionFather, setAccordionFather] = useState("adult-1");

  const validateForm = () => {
    const errors = {};
    paxes.forEach((pax, index) => {
      if (pax.type === "adult") {
        if (!pax.name) errors[`nom${index}`] = true;
        if (!pax.lastname) errors[`lastName${index}`] = true;
        if (!pax.sex) errors[`sex${index}`] = true;
        if (!pax.email) errors[`email${index}`] = true;
        if (!pax.tel) errors[`tel${index}`] = true;
        if (!pax.age) errors[`age${index}`] = true;
        if (!pax.doc) errors[`Document${index}`] = true;
        if (pax.typeDoc === "Tipo Documento") errors[`typeDoc${index}`] = true;
      } else if (pax.type === "child") {
        if (!pax.name) errors[`nom${index}`] = true;
        if (!pax.lastname) errors[`lastName${index}`] = true;
        if (!pax.sex) errors[`sex${index}`] = true;
        if (!pax.age) errors[`age${index}`] = true;
      }
    });
    setErrorForm(errors);
    return Object.keys(errors).length === 0;
  };

  const handleReservation = () => {
    setSubmitData(true);

    if (validate) {
      createReservation();
    }
  };

  function formatearCantidad(cantidad) {
    const cantidadFormateada = cantidad?.toLocaleString("es", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return cantidadFormateada;
  }

  for (let index = 0; index < cantAcomodationAdult; index++) {
    persons?.push(
      <PersonForm
        index={index + 1}
        key={index}
        type={"adult"}
        paxes={paxes}
        setPaxes={setPaxes}
        validate={validate}
        setValidate={setValidate}
        sendSubmit={submitData}
        setSubmitData={setSubmitData}
        cantAcomodationAdult={cantAcomodationAdult}
        cantAcomodationChild={cantAcomodationChild}
      />
    );
  }

  for (let index = 0; index < cantAcomodationChild; index++) {
    persons?.push(
      <PersonForm
        index={index + 1}
        key={index}
        type={"child"}
        paxes={paxes}
        setPaxes={setPaxes}
        validate={validate}
        setValidate={setValidate}
        submitData={submitData}
        setSubmitData={setSubmitData}
        cantAcomodationAdult={cantAcomodationAdult}
        cantAcomodationChild={cantAcomodationChild}
      />
    );
  }

  return (
    <div>
      <CarouselSevices current={servicesTravel[1].title} />
      <ReturnPage />
      <div className="contain-hotel-checkout">
        <div className="contain-hotel-pay">
          {loadingRespose ? (
            <Loading size={90} />
          ) : (
            <ModalContainer
              show={loadingReservation}
              hiddsetActiveChangeServiceen={setLoadingReservation}
              sizeWidth={50}
              sizeHeight={50}
              width={650}
            >
              <div className="content-modal-card conten-modal-prereserve">
                <div className="content-modal-card-title">
                  <h2>Reserva realizada con éxito en el hotel</h2>
                </div>
                <h3>Datos de la reserva</h3>
                <ul className="list-content-modal-card list-modal-preserve-modal">
                  <li>
                    <span className="label-content-modal-card">Hotel: </span>
                    <span className="value-content-modal-card">{reservation?.hotel}</span>
                  </li>
                  <li>
                    <span className="label-content-modal-card">Código de reserva: </span>
                    <span className="value-content-modal-card">{reservation?.localizator}</span>
                  </li>
                  <li>
                    <span className="label-content-modal-card">Tomador: </span>
                    <span className="value-content-modal-card">{reservation?.name}</span>
                  </li>
                  <li>
                    <span className="label-content-modal-card">Correo: </span>
                    <span className="value-content-modal-card">{reservation?.email}</span>
                  </li>
                </ul>
                <div className="price-content-modal-card">
                  <span className="value-content-modal-card">Total</span>
                  <span style={{ color: "#004274 !important", fontSize: 18 }}>
                    <b>
                      $ {new Intl.NumberFormat("es-ES", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(reservation?.price)}
                    </b>
                  </span>
                </div>
                <div className="content-btn-reserve-modal-prereserve">
                  <button onClick={functionNavigatePaceToPay} className="btn-reserve-hotel-modal-prereserve">
                    Reservar
                  </button>
                </div>
              </div>
            </ModalContainer>
          )}

          <div className="layout-hotel-pay">
            <section className="main-hotel-pay">
              <TextComponent size={"md"} fontWeight="bold" tag="h3">
                ¡Falta poco! Completa tus datos y finaliza tu compra
              </TextComponent>

              <TextComponent size={"md2"} fontWeight="bold" tag="h4">
                ¿Quién será el titular de la reserva?
              </TextComponent>

              {[...Array(cantAcomodationAdult).keys()].map((index) => (
                <PersonForm
                  index={index + 1}
                  key={index}
                  type={"adult"}
                  paxes={paxes}
                  setPaxes={setPaxes}
                  setError={setErrorForm}
                  validate={validate}
                  setValidate={setValidate}
                  submitData={submitData}
                  setSubmitData={setSubmitData}
                  accordionFather={accordionFather}
                  setAccordionFather={setAccordionFather}
                  cantAcomodationAdult={cantAcomodationAdult}
                  cantAcomodationChild={cantAcomodationChild}
                />
              ))}

              {[...Array(cantAcomodationChild).keys()].map((index) => (
                <PersonForm
                  index={index + 1}
                  key={index}
                  type={"child"}
                  paxes={paxes}
                  setPaxes={setPaxes}
                  setError={setErrorForm}
                  validate={validate}
                  setValidate={setValidate}
                  submitData={submitData}
                  setSubmitData={setSubmitData}
                  accordionFather={accordionFather}
                  setAccordionFather={setAccordionFather}
                  cantAcomodationAdult={cantAcomodationAdult}
                  cantAcomodationChild={cantAcomodationChild}
                />
              ))}

              <SendInvoices />
            </section>

            <section className="aside-hotel-pay">
              <div className="aside-hotel-pay-detail">
                <DetailPay
                  acomodation={cantAcomodationAdult + cantAcomodationChild}
                  total={state?.price}
                />
                <DetailBuy
                  canceledDay={
                    state?.listReserve[0]?.gastos?.gasto?.dias_antelacion
                  }
                  lat={state?.dataHab?.lat}
                  lon={state?.dataHab?.lon}
                  hab={state?.listReserve}
                  nom={state?.hotel}
                  stars={state?.stars}
                  arrival={state?.arrival}
                  departure={state?.departure}
                  address={state?.dataHot?.direccion}
                  checkIn={state?.dataHot?.checkin}
                  checkOut={state?.dataHot?.checkout}
                  mountRoom={mountRoom}
                />
              </div>

              <div className="content-button-reservation-hotel">
                <div>
                  <Recapchat siteKey={siteKey} onChangeCaptcha={onChangeCaptcha} />
                  {
                    verifyCaptchaErr &&
                    <TextComponent size={"md2"} color="var(--redPrimary)">
                      Debes verificar el REcaptcha
                    </TextComponent>
                  }

                </div>


                <TextComponent size={"md2"}>
                  Total a pagar:{" "}
                  <span>$ {formatearCantidad(state?.price)}</span>
                </TextComponent>
                <button onClick={handleReservation}>
                  <FaCircleCheck size={20} color="#fff" />
                  Reservar
                </button>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AnyQuestions title="¿Tienes alguna duda?" />
    </div>
  );
};

export default MakeReservation;
