import React, { useState, useRef, useEffect } from "react";
import { formatToDDMMYYYY } from "@/common/services/dateUtils";
import { Calendar } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./styles.module.css";

const DateInputWithPicker = ({
  inputId, 
  selectedDate, 
  setSelectedDate, 
  isBirthday = false,
  setBirthDate,
  setOperationDate,
  minDate = isBirthday ? new Date(1900, 0, 1) : new Date(new Date().setDate(new Date().getDate() + 1)),
  maxDate = isBirthday ? new Date() : new Date(2100, 11, 31),
  placeholder = "dd/mm/yyyy",
  icon: Icon,
  hasButton = false,
  buttonText = "Seleccionar fecha",
  validationForm,
}) => {
  const initialDate = selectedDate || (isBirthday ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1)));
  
  const [showCalendar, setShowCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  const setInitialDate = () => {
    const initialDate = isBirthday ? new Date() : new Date(new Date().setDate(new Date().getDate() + 1));
    setSelectedDate(initialDate);
    const formattedDate = formatToDDMMYYYY(initialDate);
    setInputValue(formattedDate);

    // Update specific props according to the type
    if (setBirthDate) {
      setBirthDate(formattedDate);
    }
    if (setOperationDate) {
      setOperationDate(formattedDate);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = formatToDDMMYYYY(date);
    setInputValue(formattedDate);

    if (setBirthDate) {
      setBirthDate(formattedDate);
    }
    if (setOperationDate) {
      setOperationDate(formattedDate);
    }

    if (!hasButton) {
      setShowCalendar(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Permitir vaciar el input
    if (value === "") {
      setInputValue("");
      setSelectedDate(null); // Limpia la fecha seleccionada
      if (setBirthDate) setBirthDate(null);
      if (setOperationDate) setOperationDate(null);
      return;
    }

    if (isValidDateInput(value)) {
      setInputValue(value);

      const parsedDate = parseDate(value);
      if (parsedDate && parsedDate >= minDate && parsedDate <= maxDate) {
        setSelectedDate(parsedDate);

        const formattedDate = formatToDDMMYYYY(parsedDate);
        if (setBirthDate) {
          setBirthDate(formattedDate);
        }

        if (setOperationDate) {
          setOperationDate(formattedDate);
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Tab"];
    const isNumberKey = /^[0-9]$/.test(e.key); // Regex to check if the key is a number

    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();

      if (!selectedDate) {
        setInitialDate(); // Set the initial date when interacting with the arrow keys
      } else {
        adjustDateWithArrows(e.key === "ArrowUp" ? 1 : -1);
      }
    }

    if (!isNumberKey && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleFocus = () => {
    if (!selectedDate) {
      setInitialDate(); // Set the initial date when focusing the input
    }
    setShowCalendar(true);
  };

  const handleBlur = () => {
    // If the input is empty, nothing is done
    if (!inputValue.length) {
      return;
    }

    const parsedDate = parseDate(inputValue);

    // If the date is invalid or out of the limits, it restores the default value
    if (!parsedDate || parsedDate < minDate || parsedDate > maxDate) {
      setInitialDate();
    }
  };


  const adjustDateWithArrows = (increment) => {
    const input = inputRef.current;
    const cursorPosition = input.selectionStart;
    const segments = inputValue.split("/");
    const [day, month, year] = segments.map((v) => parseInt(v, 10));

    let newDay = day || 1;
    let newMonth = month || 1;
    let newYear = year || new Date().getFullYear();

    // Adjust according to the cursor position
    if (cursorPosition <= 2) {
        newDay += increment;
    } else if (cursorPosition > 2 && cursorPosition <= 5) {
        newMonth += increment;
    } else if (cursorPosition > 5) {
        newYear += increment;
    }

    const updatedDate = new Date(newYear, newMonth - 1, newDay);

    // Validate that the new date is within the range
    if (updatedDate >= minDate && updatedDate <= maxDate) {
        const newValue = formatToDDMMYYYY(updatedDate);

        const selectionStart = input.selectionStart;
        const selectionEnd = input.selectionEnd;

        // Update values
        setInputValue(newValue);
        setSelectedDate(updatedDate);

        // Synchronize with specific functions if they are defined
        if (setBirthDate) {
            setBirthDate(newValue);
        }

        if (setOperationDate) {
            setOperationDate(newValue);
        }

        // Restore cursor position
        setTimeout(() => {
            input.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    } else {
        // SPECIAL CASE: if you try to reduce beyond the limit, correct to the minimum or maximum limit
        if (updatedDate < minDate) {
            const minValue = formatToDDMMYYYY(minDate);
            setInputValue(minValue);
            setSelectedDate(minDate);

            if (setOperationDate) {
                setOperationDate(minValue);
            }
        }
        if (updatedDate > maxDate) {
            const maxValue = formatToDDMMYYYY(maxDate);
            setInputValue(maxValue);
            setSelectedDate(maxDate);
        }
    }
  };

  const parseDate = (value) => {
    const [day, month, year] = value.split("/").map((v) => parseInt(v, 10));
    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
      return new Date(year, month - 1, day);
    }
    return null;
  };

  const isValidDateInput = (value) => {
    return /^(\d{0,2}\/\d{0,2}\/\d{0,4})?$/.test(value); // Regex to check if the input is a valid date in dd/mm/yyyy format
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <div className={styles.dateInputWithPicker} ref={wrapperRef}>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          id={inputId}
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          className={`${styles.dateInput} ${!inputValue && validationForm ? styles.errorInput : ""}`}
        />
        {Icon && (
          <span className={styles.icon} onClick={() => setShowCalendar((prev) => !prev)}>
            <Icon />
          </span>
        )}
      </div>
      {showCalendar && (
        <div className={styles.calendarContainer}>
          <Calendar
            date={selectedDate || initialDate}
            onChange={handleDateChange}
            minDate={minDate}
            maxDate={maxDate}
            className="custom-calendar"
          />
          {hasButton && (
            <div className={styles.containButtonSelectedDate}>
              <button onClick={() => setShowCalendar(false)}>{buttonText}</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DateInputWithPicker;