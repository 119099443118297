export const ApiGenders = [
    {
        "nombre": "Afganistán",
        "name": "Afghanistan",
        "nom": "Afghanistan",
        "iso2": "AF",
        "iso3": "AFG",
        "gender_name": "Masculino"
    },
    {
        "nombre": "Albania",
        "name": "Albania",
        "nom": "Albanie",
        "iso2": "AL",
        "iso3": "ALB",
        "gender_name": "Femenino"
    }, 
    {
        "nombre": "Albania",
        "name": "Albania",
        "nom": "Albanie",
        "iso2": "AL",
        "iso3": "ALB",
        "gender_name": "No especifica"
    }, 
]