import React from 'react';
import './FlightSegment.css';

const FlightSegment = ({ date, airlineLogo, departureTime, arrivalTime, departureAirport, arrivalAirport, duration, type = 'IDA' }) => {

  return (
    <>
      {
        departureAirport ?
          <div className="flight-segment">
            <div className="flight-segment-date">
              <div>
                <span>{type}</span>
                <br />
                <b>{date}</b>
              </div>

              <div className='flight-segment-details-image'>
                <img src={airlineLogo} alt="Airline Logo" className="airline-logo" />
              </div>
            </div>
            <div className="flight-segment-details">
              <div className="flight-times">
                <div className='info divider'>
                  <span>{departureAirport}</span>
                  <br />
                  <b>{departureTime}</b>
                </div>
                <div className='info divider' style={{ border: "none" }}>
                  <span>Directo</span>
                  <b>{duration}</b>
                </div>
                <div className='info divider'>
                  <span>{arrivalAirport}</span>
                  <br />
                  <b>{arrivalTime}</b>
                </div>


              </div>
            </div>
          </div>
          :

          ""
      }
    </>


  );
};

export default FlightSegment;
