import { useTranslation } from "react-i18next";
import { global } from "../../../../../../global/global";
import "./styles.css";
import { GiAirplaneArrival, GiAirplaneDeparture } from "react-icons/gi";
import TextComponent from "../../../../../share/components/fonts/TextComponent";

const { iconAirplane } = global.img.icons;

export function RowFiveColumnsTitle({
  flight,
  type, 
  origin,
  destiny,
  date,
  col1,
  col2,
  modal = false,
  showModal,
  isOurwar
}) {
  const [t, i18n] = useTranslation("global");
  const _origin = origin.split("-");
  const _destiny = destiny.split("-"); 

  return (
    <div className="rowFiveColumnsTitle">
      {modal && <div className="multi-background"></div>}
      <div className="rowFiveAirplan">
        <GiAirplaneDeparture size={23} color="#104172" />
        <span className="text-scales" onClick={() => {
          if (!modal) {
            showModal(flight, isOurwar ? "Ida" : "Vuelta");
          }
        }} style={{ fontSize: "12px", textWrap: "nowrap" }}>
          {
            flight?.segments?.length === 1
              ? "Directo"
              : flight?.segments?.length > 1
                ? `${flight?.segments?.length} escalas`
                : null
          }
        </span> 
        <GiAirplaneArrival size={23} color="#104172" />
      </div>

      <div className="rowFiveAirplanLoading">
        <div className="rowFiveAirplanLoadingBarra"></div>
      </div>

      <div className="rowFiveAirplanInfo">
        <div className="titleColText">
          <TextComponent children={!modal ? _origin[1] : origin} className={"titleColTextBig"} size={"sm"} />
        </div>

        <div className="titleColText">
          <TextComponent children={type} className={"titleColTextBig"} size={"sm"} />
        </div>
 
        <div className="titleColText">
          <TextComponent children={!modal ? _destiny[1] : destiny} className={"titleColTextBig"} size={"sm"} />
        </div> 
      </div>

      {/* <p className="titleColTextSmall">{_origin[0] || null}</p> */}
    </div>
  );
}

export function RowFiveColumnsInfo({
  isTitle = false,
  col1,
  col2,
  col3,
  col4,
  col5,
  modal,
  openModalFlight
}) {
  return (
    <div
      className={`5Columns ${isTitle ? "fiveColumnsContainerTitle" : "fiveColumnsContainerChild"
        }`}
      style={{ padding: openModalFlight && 0 }}
    >
      {!isTitle && (
        <div className={`fiveColumnsPrimaryColumnRowTriple`}>
          {!modal && <div className="fiveColumnsPrimaryColumn">{col1}</div>}
          <div className="fiveColumnsColumnsFlight">
            {modal && (
              <div className="fiveColumnsColumnsTime">
                {col1}
                {col3}
              </div>
            )}
            {col2}
          </div>
          {!modal && <div className="fiveColumnsPrimaryColumn">{col3}</div>}
        </div>
      )}
      {isTitle && <div className="fiveColumnsColumns">{col2}</div>}
      {isTitle && (
        <div className="fiveColumnsColumns fiveColumnsColumnsEmpty">{col3}</div>
      )}
      {isTitle && <div className="fiveColumnsColumns">{col4}</div>}
      {/* <div
        className={`fiveColumnsColumns ${
          !isTitle ? "package" : "packageTitle"
        }`}
      >
        {col5}
      </div> */}
    </div>
  );
}
