import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import axios from "axios";
import { IoClose } from "react-icons/io5";

const FilterSpecialist = ({
  findSpecialist,
  setCity,
  setName,
  city,
  name,
  setModal,
  seeFilter,
  specialist
}) => {
  const [cities, setCities] = useState([]);

  // Cargar las ciudades al montar el componente
  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API_FINANMED}/list/ciudades`;
    axios
      .get(url)
      .then((res) => {
        setCities(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Función para manejar la búsqueda al presionar el botón
  const handleSearch = () => {
    findSpecialist(1, name, city, specialist);
    setModal(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className={styles.contain_filters}>
      <div className={styles.filters}>
        <h2>Filtros</h2>
      </div>

      <div className={styles.contentFilter}>
        <div className={styles.filters}>
          <h2>Nombre</h2>
          <span>Filtro por nombre</span>
          <div className={styles.inputClose}>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleKeyDown}
              value={name}
              placeholder="Escribe el nombre"
            />

            {findSpecialist && (
              <button className={styles.closeButton} onClick={() => {
                setName("");
                if (!seeFilter) {
                  setCity("");
                }
                setModal(false);
                findSpecialist(1, "", !seeFilter ? "" : city, specialist);
              }}>
                <IoClose size={18} />
              </button>
            )}

          </div>
        </div>

        {/*Solo se muestra cuando no están construyendo un paquete */}
        {!seeFilter && (
          <div className={styles.filters}>
            <h2>Ciudad</h2>
            <span>Filtro por ciudad</span>
            <select
              onChange={(e) => setCity(e.target.value)}
              value={city}
            >
              <option value="">Seleccione</option>
              {cities.map((city, index) => (
                <option key={index} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <button className={styles.buttonSearch} onClick={handleSearch}>
          Buscar
        </button>
      </div>
    </div>
  );
};

export default FilterSpecialist;
