export const parseDDMMYYYYToDate = (dateString) => {
    try {
        const [day, month, year] = dateString.split("/").map(Number);
        return new Date(year, month - 1, day); // Rest 1 to month because month is 0-indexed
    } catch (error) {
        console.error("Error parsing date:", error);
        return dateString;
    }
};    

export const formatToISO = (dateString) => {
    try {
        const date = parseDDMMYYYYToDate(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    } catch (error) {
        console.error("Error parsing date:", error);
        return dateString;
    }
};
  

export const formatToDDMMYYYY = (date) => {
    try {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${day}/${month}/${year}`;
    } catch (error) {
        console.error("Error parsing date:", error);
        return date;
    }
};