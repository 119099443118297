import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { global } from "../../global";
import { set } from "date-fns";

const {
  img: {
    logos: { AviancaLogo, LatamLogo, VivaLogo },
  },
} = global;

function useFlightsSearch() {
  const { pathname, state } = useLocation();

  const extract = (stringt) => {
    const startIndex = pathname.indexOf(stringt);
    if (startIndex !== -1) {
      // Encontrado, buscar el índice del ampersand siguiente
      const endIndex = pathname.indexOf("&", startIndex);

      if (endIndex !== -1) {
        // Extraer el valor de dateOfDeparture
        const dateOfDeparture = pathname.slice(
          startIndex + stringt.length,
          endIndex
        );

        return dateOfDeparture;
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [flightsList, setFlightsList] = useState([]);
  const numberSearchs = 50;
  const classFlight = false;
  const [directFlight, setDirectFlight] = useState(extract("directFlight=") === "false" ? false : true);
  const [dollarFlight, setDollarFlight] = useState(extract("dollarFlight="));
  const [allPassengers, setAllPassengers] = useState({
    adult: Number(extract("adult=")),
    boy: Number(extract("boy=")),
    baby: Number(extract("baby=")),
    seat: Number(extract("seat=")),
  });
  const [isRoundTripB, setIsRoundTripB] = useState(extract("isRoundTrip=") === "false" ? false : true);
  const [allPassengersSelected, setAllPassengersSelected] = useState(
    state === null ? allPassengers : state?.passengersSelecteds
  );
  const [numberOfAdultPassengers, setNumberOfAdultPassengers] = useState(Number(extract("adult=")));
  const [numberOfBoyPassengers, setNumberOfBoyPassengers] = useState(Number(extract("boy=")));
  const [numberOfBabyPassengers, setNumberOfBabyPassengers] = useState(Number(extract("baby=")));
  const [numberOfSeatPassengers, setNumberOfSeatPassengers] = useState(Number(extract("seat=")));
  const errorGetFlight = "Request failed with status code 500";

  const [nameOrigin, setNameOrigin] = useState(decodeURIComponent(extract("originC=")));

  const [nameDestiny, setNameDestiny] = useState(decodeURIComponent(extract("destinationC=")));

  const [dateGoingSelected, setDateGoingSelected] = useState("");
  const [dateGoingSelectedApi, setDateGoingSelectedApi] = useState(extract("departure="));
  const [dateReturnSelected, setDateReturnSelected] = useState("");
  const [dateReturnSelectedApi, setDateReturnSelectedApi] = useState(extract("return="));

  const [codeIataDestiny, setCodeIataDestinity] = useState(extract("iataD="));
  const [codeIataOrigin, setCodeIataOrigin] = useState(extract("iataO="));

  const airlinesList = [
    {
      id: 1,
      img: VivaLogo,
      name: "viva air",
      priceDirect: 1050,
      priceOneScale: 950,
      priceMoreScale: null,
    },
    {
      id: 2,
      img: LatamLogo,
      name: "latam airlines",
      priceDirect: 1100,
      priceOneScale: 1080,
      priceMoreScale: null,
    },
    {
      id: 3,
      img: AviancaLogo,
      name: "avianca",
      priceDirect: 1120,
      priceOneScale: 1100,
      priceMoreScale: 1050,
    },
  ];



  let dataToSend = {
    direct: directFlight,
    currency: dollarFlight,
    searchs: numberSearchs,
    class: classFlight,
    qtyPassengers: numberOfAdultPassengers + numberOfBoyPassengers + numberOfSeatPassengers,
    adult: numberOfAdultPassengers,
    child: numberOfBoyPassengers,
    baby: numberOfBabyPassengers,
    seat: numberOfSeatPassengers,
    itinerary: [
      {
        departureCity: `${codeIataOrigin}`,
        arrivalCity: `${codeIataDestiny}`,
        hour: `${dateGoingSelectedApi}`,
      },
    ],
  };


  

  useEffect(() => {
    setNameOrigin(decodeURIComponent(extract("originC=")));
    setNameDestiny(decodeURIComponent(extract("destinationC=")));
    setAllPassengers({
      adult: Number(extract("adult=")),
      boy: Number(extract("boy=")),
      baby: Number(extract("baby=")),
      seat: Number(extract("seat=")),
    })
    setDirectFlight(extract("directFlight="));
    setDollarFlight(extract("dollarFlight="));
    setAllPassengersSelected({
      adult: Number(extract("adult=")),
      boy: Number(extract("boy=")),
      baby: Number(extract("baby=")),
      seat: Number(extract("seat=")),
    });
    setIsRoundTripB(extract("isRoundTrip="));
    setCodeIataDestinity(extract("iataD="));
    setCodeIataOrigin(extract("iataO="));
    setDateGoingSelectedApi(extract("departure="));
    setDateReturnSelectedApi(extract("return="));
    setNumberOfAdultPassengers(Number(extract("adult=")));
    setNumberOfBoyPassengers(Number(extract("boy=")));
    setNumberOfBabyPassengers(Number(extract("baby=")));
    setNumberOfSeatPassengers(Number(extract("seat=")));

    dataToSend = {
      direct: extract("directFlight=") === "false" ? false : true,
      currency: extract("dollarFlight="),
      searchs: numberSearchs,
      class: classFlight,
      qtyPassengers: Number(extract("adult=")) + Number(extract("boy=")) + Number(extract("seat=")),
      adult: Number(extract("adult=")),
      child: Number(extract("boy=")),
      baby: Number(extract("baby=")),
      seat: Number(extract("seat=")),
      itinerary: [
        {
          departureCity: extract("iataO="),
          arrivalCity: extract("iataD="),
          hour: extract("departure="),
        },
      ],
    };

    if (extract("isRoundTrip=") == "true") {
      dataToSend.itinerary.push({
        departureCity: extract("iataD="),
        arrivalCity: extract("iataO="),
        hour: extract("return="),
      })
    }
    getFlights();
  }, [pathname]);


function getFlights() {
    setLoading(true);
    setQueryError(false);
    const url = `${process.env.REACT_APP_URL_API_FLY_NEW}flights/v2`;
    axios
      .post(url, dataToSend)
      .then((res) => {
        setFlightsList(res?.data?.data);
      })
      .catch((err) => {
        console.log({ err });
        if (err.message === errorGetFlight) {
          console.log("no hay resultados");
          setQueryError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return {
    loading,
    isRoundTripB,
    setIsRoundTripB,
    queryError,
    flightsList,
    airlinesList,
    nameOrigin,
    setNameOrigin,
    nameDestiny,
    setNameDestiny,
    getFlights,
    allPassengersSelected,
    setAllPassengersSelected,
    setDateGoingSelected,
    dateGoingSelected,
    setDateGoingSelectedApi,
    setDateReturnSelected,
    setDateReturnSelectedApi,
    dateReturnSelected,
    dollarFlight,
    setDollarFlight
  };
}

export default useFlightsSearch;
