import "./styles.css";
import usePassengerInformationForm from "../../../viewModels/flights/passenger.information.form.viewModel";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Loading from "../../share/components/loading";
import CarouselSevices, {
  servicesTravel,
} from "../../share/components/CarouselServices/CarouselSevices";
import DetailPayFlight from "./components/detailPayFlight/DetailPay";
import { useEffect, useState } from "react";
import usePnrFlight from "../../../viewModels/flights/usePnrFlight";
import PersonFormFlight from "./components/PersonForm/PersonFormFlight";
import TextComponent from "../../share/components/fonts/TextComponent";
import ReturnPage from "../../share/components/return/ReturnPage";
import { FaCircleCheck } from "react-icons/fa6";
import { currencyFormat } from "../../../utils";
import SessionModal from "../../session/components/SessionModal/SessionModal";
import AnyQuestions from "../../home/components/anyQuestion";
import Recapchat from "../../share/components/recapchat/Recapchat";
import UseRecapchat from "@/Hooks/useRecapchat/UseRecapchat";
import ModalContainer from "../../share/components/modalContainer";
import styles from "./styles.module.css"
import { IoClose, IoCloseOutline } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FcAdvertising } from "react-icons/fc";
import { SiWhatsapp } from "react-icons/si";

function PassengerInformationForm() {
  const [numberPhones, setNumberPhones] = useState([]);

  const {
    verifyCaptcha,
    onChangeCaptcha,
    siteKey
  } = UseRecapchat()

  const navigate = useNavigate()

  const {
    savePNRN,
    formatearCantidad,
    volverPaginaAnterior,
    state,
    persons,
    childs,
    babys,
    errorReservation,
    buildTravellers,
    setTravellers,
    travellers,
    loadingPnr,
    typeFlight,
    saveInfo,
    setSaveInfo,
    modalSucessor,
    setModalSucessor,
    codePNR
  } = usePnrFlight(verifyCaptcha);

  const [t] = useTranslation("global");
  let indicator = 1;

  const [accordion, setAccordion] = useState(1);
  const [checks, setChecks] = useState([]);
  const [saveInfoCount, setSaveInfoCount] = useState(0);
  const [validate, setValidate] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  let totalForms = 0;
  const [isAllInfoSaved, setIsAllInfoSaved] = useState(false);
  const typeOfCoin = {
    name: state?.currency,
    abbreviation: state?.currency,
    simbol: "$",
  };

  const handleCheckSuccess = (index, value) => {
    console.log(value);

  };

  const allSuccess = checks.every(check => check === true);

  // useEffect(() => {
  //   if (saveInfoCount === totalForms) {
  //     setIsAllInfoSaved(true);
  //   }
  // }, [saveInfoCount, totalForms]);

  const handleSaveClick = () => {
    setSubmitData(true);

    if (allSuccess) {
      setSaveInfoCount(0);
      setSaveInfo(!saveInfo);
      setIsAllInfoSaved(false);
      savePNRN();
      persons.forEach((person) => {
        person.props.onCount();
      });
    }
  };

  // useEffect(() => {
  //   if (isAllInfoSaved) {
  //     savePNRN();
  //   }
  // }, [isAllInfoSaved]);

  const renderPersons = (type, count) => {
    const personsArray = [];
    for (let index = 0; index < count; index++) {
      personsArray.push(
        <PersonFormFlight
          typeFlight={typeFlight}
          index={indicator}
          indicator={indicator}
          key={index + (type === 'adult' ? 10 : type === 'child' ? 20 : type === 'baby' ? 30 : 40)}
          type={type}
          setPaxes={setTravellers}
          setAccordion={setAccordion}
          accordion={accordion}
          saveInfo={saveInfo}
          onCount={() => setSaveInfoCount((prev) => prev + 1)}
          setSaveInfo={setSaveInfo}
          paxes={travellers}
          validate={validate}
          setValidate={setValidate}
          submitData={submitData}
          setSubmitData={setSubmitData}
          onCheckSuccess={(value) => handleCheckSuccess(index, value)}
        />
      );
      indicator++;
      totalForms++;
    }
    return personsArray;
  };


  persons.push(...renderPersons('adult', state?.passengersSelected?.adult));
  persons.push(...renderPersons('child', state?.passengersSelected?.boy));
  persons.push(...renderPersons('baby', state?.passengersSelected?.baby));
  persons.push(...renderPersons('seat', state?.passengersSelected?.seat));


  const deletePhoneNumber = (index) => {
    const updatedPhones = numberPhones.filter((_, i) => i !== index);
    setNumberPhones(updatedPhones);
  };

  const [activeSessionModal, setActiveSessionModal] = useState(true);


  return (
    <>
      <CarouselSevices current={servicesTravel[0].title} />
      <ReturnPage />
      <Toaster position="top-center" reverseOrder={false} />
      <ModalContainer
        show={modalSucessor}
        hiddsetActiveChangeServiceen={(e) => {
          navigate("/fligths")
        }}
      >
        <div
          className={`${styles.containModal} ${styles.active}`}
        >
          <div
            className={`${styles.contentModal} ${styles.active}`}
          >

            <div className={styles.iconSuccess}>
              <FcAdvertising size={45} />
            </div>

            <div className={styles.contentText}>
              <h2>¡Ups!</h2>
              <p>
                <span style={{ fontSize: "20px", fontWeight: "600" }}>¡Ya tienes una reserva en proceso!</span> <br /><br /> Si deseas continuar con el pago, te recomendamos hablar con nuestros asesores o esperar 20 minutos para volver a reservar tu vuelo.
              </p>
            </div>

            <a className={styles.linkCreatePackage} href={`https://api.whatsapp.com/send/?phone=573004040116&text=%C2%A1Hola%21%20Estaba%20en%20la%20p%C3%A1gina%20de%20Wellezy.com%20y%20tom%C3%A9%20el%20itinerario%20${codePNR}%2C%20pero%20no%20logr%C3%A9%20completar%20el%20pago.%20%C2%BFPodr%C3%ADan%20ayudarme%20a%20recuperar%20el%20itinerario%20para%20finalizar%20la%20compra%3F%20Agradezco%20su%20ayuda.&type=phone_number&app_absent=0
`} target="_blank">
              <SiWhatsapp size={20} /> Linea Unica
            </a>
          </div>
        </div>
      </ModalContainer>
      {loadingPnr && <Loading size={90} />}
      <div className="contain-hotel-pay">

        <div className="contain-hotel-pay-title">
          <h2>
            Información de pasajeros
          </h2>
          <p>
            Completa tus datos y finaliza tu compra
          </p>

        </div>


        <div className="layout-hotel-pay">
          <section className="main-hotel-pay">
            {persons?.map((item, index) => {
              return item;
            })}

            <div className="form-persons-pay-flight">
              <TextComponent
                children={t("fligth.what_number")}
                color={"#004274"}
                size={"md"}
                fontWeight="bold"
              />

              <div className="camp-form-person-hotel">
                <label htmlFor="">{t("fligth.table_passengers.phone")}</label>
                <input
                  type="text"
                  onChange={(e) => { }}
                  placeholder={t("fligth.table_passengers.phone")}
                />
              </div>

              <div className="camp-form-person-hotel">
                <label htmlFor="">{t("fligth.country_code")}</label>
                <input
                  type="text"
                  onChange={(e) => { }}
                  placeholder={t("Colombia (57)")}
                />
              </div>



              <button onClick={() => setNumberPhones([...numberPhones, ""])} className="addNumbersFlights">
                <TextComponent
                  children={t("fligth.addnumner")}
                  color={"#004274"}
                  size={"md2"}
                  fontWeight="bold"
                />
              </button>

              {numberPhones.map((_, index) => (
                <div key={index} className="camp-form-person-flight fade-in">
                  <div className="camp-form-person-flight-input">
                    <label htmlFor="">{t("fligth.number")}</label>
                    <input
                      type="text"
                      onChange={(e) => { }}
                      placeholder={`${t("fligth.example")}: +57 3005827489`}
                    />
                  </div>
                  <div className="content-button-reservation-flight">
                    <button onClick={() => deletePhoneNumber(index)}>
                      <IoCloseOutline size={20} />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* <SendInvoicesFlight /> */}
          </section>
          <section className="aside-hotel-pay" onClick={() => setActiveSessionModal(true)}>
            <DetailPayFlight
              hab={state}
              acomodation={persons.length}
              total={state?.price}
              typeOf={typeOfCoin}
            />
            <Recapchat siteKey={siteKey} onChangeCaptcha={onChangeCaptcha} />
            <div className="content-button-reservation-hotel">
              <p>
                Total a pagar{" "}
                <span>
                  $ {currencyFormat(state?.priceTotal, typeOfCoin).total}
                </span>
              </p>
              <button
                onClick={() => {
                  handleSaveClick();
                }}
              >
                {" "}
                <FaCircleCheck size={20} color="#fff" />
                Comprar
              </button>

              {/* {errorReservation != "" && (
                <div className="err-hotel-form-person">
                  <TextComponent
                    children={errorReservation}
                    size={"md"}
                    color={"white"}
                    fontWeight="bold"
                  />
                </div>
              )} */}
            </div>
          </section>
        </div>
      </div>
      <AnyQuestions title={t("browse_home.have_doubt")} />
    </>
  );
}

export default PassengerInformationForm;
