import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'


const usePayFlights = () => {

  const { state } = useLocation()
  const [loading, setLoading] = useState(true)
  const [idRequest, setIdRequest] = useState(localStorage.getItem("request-id-pay"))
  const [flightInfo, setFlightInfo] = useState(!state ? JSON.parse(localStorage.getItem('data-pay-flight')) : state)
  const [incompleteInformation, setIncompleteInformation] = useState("")
  const [statePay, setStatePay] = useState()

  const getStatePay = () => {
    const url = `${process.env.REACT_APP_URL_STATE_PAY}amadeus`

    axios.post(url, {
      request_id: Number(idRequest)
    })
      .then(res => {
        setStatePay(res.data)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }


  useEffect(() => {
    getStatePay()
  }, [])



  return {
    flightInfo,
    loading,
    incompleteInformation,
    statePay
  }
}

export default usePayFlights