import "./styles.css";
import SvgIcon, { iconList } from "../../share/components/iconSVG";
import { Record, RecordCircle } from "iconsax-react";
import DesinitySearcher from "../components/desinitySearcher";
import FlightPassengerManagement from "../components/ flightPassengerManagement";
import FlightsDateGoing from "../../../../Components/FlightsDateGoing/FlightsDateGoing";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import TooltipContainer from "../../share/components/tooltipContainer";
import { IoSearchOutline } from "react-icons/io5";
import TextComponent from "../../share/components/fonts/TextComponent";
import { IoLocationOutline } from "react-icons/io5";

function SearchFlights({
  padding = false,
  accompanied = true,
  isIframe = false,
  cardRefPlace,
  cardRefPassenger,
  cardRefDate,
  buttonDisable,
  searcherFlight,
  isRoundTrip,
  setIsRoundTrip,

  //ciudades
  //ida
  isCardRefOrigin,
  setIsCardRefOrigin,
  selectedOrigin,
  getSelectedOrigin,

  //retorno
  isCardRefDestination,
  setIsCardRefDestination,
  selectedDestination,
  getSelectedDestination,
  //fechas
  dateGoingSelected,
  setDateGoingSelected,
  dateReturnSelected,
  setDateReturnSelected,
  isCardRefDate,
  setIsCardRefDate,
  setDateGoingSelectedApi,
  setDateReturnSelectedApi,

  //pasajeros
  isManagingPassengers,
  setIsManagingPassengers,
  passengersSelecteds,
  getPassengersSelecteds,
  ellipsisText,
  setPass,

  //Validation
  validationSearchDestiny,
  setValidationSearchDestiny,
  validationSearchDate,

  isScrolled,
  showSearchScrolled,

  // Validacion de fechas
  checkDate = false,
  setCheckDate = false,

  // Validación de vulo default
  setFlightDefault = false,
  flightDefault = false,
}) {
  const [t, i18n] = useTranslation("global");
  const { pathname } = useLocation();
  const [inputFocused, setInputFocused] = useState(true);
  const [inputFocusedD, setInputFocusedD] = useState(true);
  const containerRefDes = useRef(null);
  const containerRefOri = useRef(null);
  const [focusOrigin, setFocusOrigin] = useState(false);
  const [focusDestination, setFocusDestination] = useState(false);
  const [dateOneWay, setDateOneWay] = useState(
    localStorage.getItem("dateOne")
      ? new Date(localStorage.getItem("dateOne"))
      : new Date()
  );
  const [dateReturn, setDateReturn] = useState(
    localStorage.getItem("dateReturn")
      ? new Date(localStorage.getItem("dateReturn"))
      : new Date()
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        containerRefDes.current &&
        !containerRefDes.current.contains(event.target)
      ) {
        setInputFocusedD(false);
      } else if (
        !containerRefDes.current ||
        containerRefDes.current.contains(event.target)
      ) {
        setInputFocusedD(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const location = useLocation();
  let data = {};
  
  const pathSegments = location.pathname.split('/');
  if (pathSegments.length === 3 && pathSegments[1] === 'flight') {
    const dataString = decodeURIComponent(pathSegments[2]);
    
    const params = new URLSearchParams(dataString);
    data = Object.fromEntries(params.entries());
  }

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return "Fecha inválida";
    }
  
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = new Intl.DateTimeFormat('es-ES', options).format(date);
  
    const [day, month, year] = formattedDate.split(' ');
    const abbreviatedMonth = month.slice(0, 3);
  
    return `${day}, ${abbreviatedMonth}, ${year}`;
  };

  useEffect(() => {
    //Recuperacion de la fecha

    setDateOneWay(
      (() => {
        const storedDate = localStorage.getItem("dateOne");
        const dateOne = storedDate === null ? new Date() : new Date(storedDate);
    
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00 para comparar solo la fecha
    
        return dateOne < today ? today : dateOne;
      })()
    );
    
    if (!data.departure) {
      setDateGoingSelected("");
    } else {
      const startDate = new Date(dateOneWay);      
      const formattedDate = !isNaN(startDate) ? startDate.toISOString() : new Date().toISOString();
      setDateGoingSelected(formattedDate);
      setDateGoingSelectedApi(formattedDate);
    }

    setDateReturn(
      (() => {
        const storedDate = localStorage.getItem("dateReturn");
        const dateReturn = storedDate === null ? new Date() : new Date(storedDate);
    
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establecer la hora a 00:00:00 para comparar solo la fecha
    
        if (dateReturn < today) {
          const tomorrow = new Date(today);
          tomorrow.setDate(today.getDate() + 1); // Establecer un día después de hoy
          return tomorrow;
        }
    
        return dateReturn;
      })()
    );

    if (!data.return) {
      setDateReturnSelected("");
    } else {
      const endDate = new Date(dateReturn);
      const formattedDate = !isNaN(endDate) ? endDate.toISOString() : new Date().toISOString();
      setDateReturnSelected(formattedDate);
      setDateReturnSelectedApi(formattedDate);
    }

    function handleClickOutside(event) {
      if (
        containerRefOri.current &&
        !containerRefOri.current.contains(event.target)
      ) {
        setInputFocused(false);
      } else if (
        !containerRefOri.current ||
        containerRefOri.current.contains(event.target)
      ) {
        setInputFocused(true);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const desinitySearcherTypes = {
    origin: {
      text: t("fligth.search_fligth.origin"),
      errorText: t("fligth.search_fligth.errorMessage"),
      placeholder: t("fligth.search_fligth.placeholderOrigin"),
    },
    destination: {
      text: t("fligth.search_fligth.destination"),
      errorText: t("fligth.search_fligth.errorMessageDestination"),
      placeholder: t("fligth.search_fligth.placeholderDestination"),
    },
  };

  // const [background, setBackground] = useState(0);

  // const selectTypeResercation = (type) => {
  //   type === 1 ? setBackground(type) : setBackground(type);
  // };

  useEffect(() => {
    if (isCardRefDate) {
      const scrollPosition = window.innerWidth < 765 ? 450 : 350;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [isCardRefDate]);

  return accompanied ? (
    <div className="searchFlightsContainerSticky">
      <div
        className="searchFlightsContainerHeader"
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <div className="searchFlightsRow">
          <div className="searchFlightsContainerHeaderOption">
            <SvgIcon
              name={iconList.IconPlane}
              color={"var(--bluePrimary)"}
              size={30}
            />
            <TextComponent
              className={"searchFlightsContainerHeaderOptionTextBig"}
              children={t("fligth.search_fligth.book_fligth")}
              fontWeight={"bold"}
              size={"md"}
            />
          </div>
        </div>
        <div
          className={`${
            accompanied ? "searchFlightsColumn" : "searchFlightsRow"
          }`}
          id={accompanied ? "" : "column-round-trip"}
        >
          <div
            className="searchFlightsContainerHeaderOption"
            onClick={() => {
              setIsRoundTrip(false);
              setCheckDate(false);
            }}
          >
            {isRoundTrip ? (
              <Record size="15" color="var(--blueSecundary)" />
            ) : (
              <RecordCircle
                size="15"
                color="var(--blueSecundary)"
                variant="Bold"
              />
            )}
            <TextComponent
              className={"searchFlightsContainerHeaderOptionTextSmall"}
              children={t("fligth.search_fligth.one_way")}
              size={"sm"}
            />
          </div>
          <div
            className="searchFlightsContainerHeaderOption"
            onClick={() => {
              setIsRoundTrip(true);
              setCheckDate(true);
            }}
          >
            {isRoundTrip ? (
              <RecordCircle
                size="15"
                color="var(--blueSecundary)"
                variant="Bold"
              />
            ) : (
              <Record size="15" color="var(--blueSecundary)" />
            )}
            <TextComponent
              className={"searchFlightsContainerHeaderOptionTextSmall"}
              children={t("fligth.search_fligth.round_trip")}
              size={"sm"}
            />
          </div>
        </div>
      </div>
      <div className={`${accompanied ? "" : "display-button-search"}`}>
        <div
          className={`${
            accompanied
              ? "searchFlightsContainerBodyVertical"
              : "searchFlightsContainerBody"
          }`}
        >
          <div ref={containerRefOri} className="input-destiny-origin-fligth">
            <div className="content-inputs">
              <div className="first-section">
                <TextComponent
                  className={"label-destiny"}
                  children={
                    selectedOrigin != "o" || validationSearchDestiny
                      ? t("fligth.search_fligth.origin")
                      : "Ingresa un Origen"
                  }
                  size={"md2"}
                  fontWeight={"light"}
                  color={
                    selectedOrigin != "o" || validationSearchDestiny
                      ? ""
                      : "var(--redPrimary)"
                  }
                />
                {selectedOrigin == "o" ? (
                  <DesinitySearcher
                    type={desinitySearcherTypes.origin}
                    change={getSelectedOrigin}
                    inputFocused={inputFocused}
                    showSearchScrolled={showSearchScrolled}
                    isScrolled={isScrolled}
                  />
                ) : selectedOrigin === selectedDestination ? (
                  <TextComponent
                    className={"value-origin-destiny"}
                    color={"var(--redPrimary)"}
                    children={ellipsisText("Cambia tu origen", 17)}
                    size={"md2"}
                    fontWeight={"bold"}
                    onclick={{
                      voidP: (pa) => getSelectedOrigin(pa),
                      parameters: "o",
                    }}
                  />
                ) : (
                  <TextComponent
                    className={"value-origin-destiny"}
                    children={selectedOrigin}
                    size={"md2"}
                    fontWeight={"bold"}
                    onclick={{
                      voidP: (pa) => getSelectedOrigin(pa),
                      parameters: "o",
                    }}
                  />
                )}
              </div>
              {selectedOrigin === "o" ? (
                ""
              ) : (
                <SvgIcon
                  name={iconList.IconPinMap}
                  color={"var(--bluePrimary)"}
                  size={30}
                />
              )}
            </div>
          </div>

          <div ref={containerRefDes} className="input-destiny-origin-fligth">
            <div className="content-inputs">
              <div className="first-section">
                <TextComponent
                  className={"label-destiny"}
                  children={
                    selectedDestination != "d" || validationSearchDestiny
                      ? t("fligth.search_fligth.destination")
                      : "Ingresa un Destino"
                  }
                  size={"md2"}
                  fontWeight={"light"}
                  color={
                    selectedDestination != "d" || validationSearchDestiny
                      ? ""
                      : "var(--redPrimary)"
                  }
                />
                {selectedDestination == "d" ? (
                  <DesinitySearcher
                    type={desinitySearcherTypes.destination}
                    change={getSelectedDestination}
                    inputFocused={inputFocusedD}
                  />
                ) : selectedOrigin === selectedDestination ? (
                  <TextComponent
                    className={"value-origin-destiny"}
                    color={"var(--redPrimary)"}
                    children={ellipsisText("Cambia tu destino", 17)}
                    size={"md2"}
                    fontWeight={"bold"}
                    onclick={{
                      voidP: (pa) => getSelectedDestination(pa),
                      parameters: "d",
                    }}
                  />
                ) : (
                  <TextComponent
                    className={"value-origin-destiny"}
                    children={selectedDestination}
                    size={"md2"}
                    fontWeight={"bold"}
                    onclick={{
                      voidP: (pa) => getSelectedDestination(pa),
                      parameters: "d",
                    }}
                  />
                )}
              </div>
              {selectedDestination === "d" ? (
                ""
              ) : (
                <SvgIcon
                  name={iconList.IconPinMap}
                  color={"var(--bluePrimary)"}
                  size={30}
                />
              )}
            </div>
          </div>

          <div
            className={`searchFlightsRow ${
              accompanied
                ? "searchFlightsContainerRowAlfa"
                : "searchFlightsContainerRowBeta"
            }`}
          >
            <div
              className="searchFlightsContainerBodyBorder"
              id="width_C"
              onClick={() => {
                setCheckDate(false);
                setIsCardRefDate(true);
              }}
            >
              <div className="searchFlightsContainerBodyOptionTexts">
                <p className="searchFlightsContainerBodyOptionTextSmall">
                  {t("fligth.search_fligth.date")}
                </p>
                <p className="searchFlightsContainerBodyOptionTextBig">
                  {dateGoingSelected === ""
                    ? t("fligth.search_fligth.going")
                    : dateGoingSelected}
                </p>
              </div>
              <SvgIcon
                name={iconList.IconCalendar}
                color={"var(--bluePrimary)"}
                size={30}
              />
            </div>

            <div
              className="searchFlightsContainerBodyBorder"
              id="width_C"
              onClick={() => {
                if (isRoundTrip) {
                  setCheckDate(true);
                  setIsCardRefDate(true);
                }
              }}
              style={{ opacity: !isRoundTrip ? 0.25 : 1 }}
            >
              <div className="searchFlightsContainerBodyOptionTexts">
                <p className="searchFlightsContainerBodyOptionTextSmall">
                  {t("fligth.search_fligth.date")}
                </p>
                <p className="searchFlightsContainerBodyOptionTextBig">
                  {dateReturnSelected === ""
                    ? t("fligth.search_fligth.return")
                    : dateReturnSelected}
                </p>
              </div>
              <SvgIcon
                name={iconList.IconCalendar}
                color={"var(--bluePrimary)"}
                size={30}
              />
            </div>

            {isCardRefDate && (
              <TooltipContainer
                show={isCardRefDate}
                hidden={setIsCardRefDate}
                sizeWidth={70}
                sizeHeight={80}
              >
                <div className="searchFlightsContainerDate">
                  <FlightsDateGoing
                    oneWay={!isRoundTrip}
                    setOneWay={setIsRoundTrip}
                    roundTrip={isRoundTrip}
                    setRoundTrip={setIsRoundTrip}
                    setDateGoing={setIsCardRefDate}
                    dateGoing={isCardRefDate}
                    cardRef={cardRefDate}
                    setDateGoingSelected={setDateGoingSelected}
                    setDateGoingSelectedApi={setDateGoingSelectedApi}
                    setDateSreturnSelected={setDateReturnSelected}
                    setDateSreturnSelectedApi={setDateReturnSelectedApi}
                    searcherFlight={searcherFlight}
                    checkDate={checkDate}
                    setCheckDate={setCheckDate}
                    dateGoingSelected={dateGoingSelected}
                    dateSreturnSelected={dateReturnSelected}
                    accompanied={accompanied}
                    setDateOneWay={setDateOneWay}
                    setDateReturn={setDateReturn}
                    dateOneWay={dateOneWay}
                    dateReturn={dateReturn}
                    setIsManagingPassengers={setIsManagingPassengers}
                  />
                </div>
              </TooltipContainer>
            )}
          </div>

          <div
            className="inputs-triple-flight"
            onClick={() => setIsManagingPassengers(true)}
          >
            <div className="contain-label-date">
              <TextComponent
                className={"label-destiny"}
                children={t("fligth.search_fligth.passengers")}
                size={"md2"}
                fontWeight={"light"}
              />
              <TextComponent
                className={"select-destiny-input"}
                children={
                  passengersSelecteds.adult +
                  passengersSelecteds.baby +
                  passengersSelecteds.boy +
                  " " +
                  t("fligth.search_fligth.passengers")
                }
                size={"md2"}
                fontWeight={"bold"}
              />
            </div>
            <SvgIcon
              color={"var(--bluePrimary)"}
              name={iconList.IconTwoPerson}
              size={30}
            />

            {isManagingPassengers && (
              <TooltipContainer
                show={isManagingPassengers}
                hidden={setIsManagingPassengers}
                sizeWidth={70}
                sizeHeight={40}
              >
                <div>
                  <FlightPassengerManagement
                    value={passengersSelecteds}
                    onChange={getPassengersSelecteds}
                    setIsManagingPassengers={setIsManagingPassengers}
                    refPass={cardRefPassenger}
                    isManagingPassengers={isManagingPassengers}
                  />
                </div>
              </TooltipContainer>
            )}
          </div>
        </div>
        {pathname === "/packages" ? (
          ""
        ) : (
          <button
            disabled={buttonDisable}
            onClick={searcherFlight}
            className="button-search-flight"
            id={`${accompanied ? "width_E" : "width_D"}`}
          >
            <SvgIcon
              name={iconList.IconSearch}
              color={buttonDisable ? "var(--greyPrimary)" : "var(--white)"}
              size={30}
            />
          </button>
        )}
      </div>
    </div>
  ) : (
    <div style={{ position: "relative", padding: "0px", width: "100%" }}>
      {isCardRefDate && flightDefault && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
            pointerEvents: "none",
          }}
        />
      )}
      {isManagingPassengers && flightDefault && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
            pointerEvents: "none",
          }}
        />
      )}
      <div
        className={`${
          pathname === "/"
            ? "searchFlightsContainerSticky"
            : "searchFlightsContainerSticky "
        }`}
        style={{
          boxShadow: `${pathname === "/packages" ? "none" : ""}`,
          position: "relative",
          zIndex: 1000,
          padding: pathname.includes("/flight/") ? "0px" : "20px",
          margin: pathname.includes("/") ? "0px" : "20px",
        }}
      >
        <div className="searchFlightsContainerHeader">
          <div className="searchFlightsRow">
            <div className="searchFlightsContainerHeaderOption">
              <SvgIcon
                name={iconList.IconPlane}
                color={"var(--bluePrimary)"}
                size={30}
              />
              <TextComponent
                className={"searchFlightsContainerHeaderOptionTextBig"}
                children={t("fligth.search_fligth.book_fligth")}
                size={"md"}
                fontWeight={"bold"}
              />
            </div>
          </div>
          <div
            className={`${
              accompanied ? "searchFlightsColumn" : "searchFlightsRow"
            }`}
            id={accompanied ? "" : "column-round-trip"}
          >
            <div
              className="searchFlightsContainerHeaderOption"
              onClick={() => {
                setIsRoundTrip(false);
                setDateOneWay(new Date());
                setDateGoingSelected("");
                setCheckDate(false);
              }}
            >
              {isRoundTrip ? (
                <Record size="15" color="var(--blueSecundary)" />
              ) : (
                <RecordCircle
                  size="15"
                  color="var(--blueSecundary)"
                  variant="Bold"
                />
              )}
              <TextComponent
                className={"searchFlightsContainerHeaderOptionTextSmall"}
                children={t("fligth.search_fligth.one_way")}
                size={"sm"}
              />
            </div>
            <div
              className="searchFlightsContainerHeaderOption"
              onClick={() => {
                setIsRoundTrip(true);
                setDateOneWay(new Date());
                setDateGoingSelected("");
                setDateReturn(new Date());
                setDateReturnSelected("");
                setCheckDate(true);
              }}
            >
              {isRoundTrip ? (
                <RecordCircle
                  size="15"
                  color="var(--blueSecundary)"
                  variant="Bold"
                />
              ) : (
                <Record size="15" color="var(--blueSecundary)" />
              )}
              <TextComponent
                className={"searchFlightsContainerHeaderOptionTextSmall"}
                children={t("fligth.search_fligth.round_trip")}
                size={"sm"}
              />
            </div>
          </div>
        </div>
        <div className="contain-btn-camps-flight">
          <div className="content-all-camps-search">
            <div className="content-inputs-destiny-dual">
              <div
                className="input-destiny-origin"
                style={{
                  border:
                    selectedOrigin != "o" || validationSearchDestiny
                      ? "var(--greyPrimary) solid 1px"
                      : "var(--redPrimary) solid 1px",
                }}
              >
                <div className="content-inputs">
                  <div className="first-section">
                    <TextComponent
                      className={"label-destiny"}
                      children={t("fligth.search_fligth.origin")}
                      size={"md2"}
                      fontWeight={
                        selectedOrigin != "o" || validationSearchDestiny
                          ? "light"
                          : "400"
                      }
                      color={
                        selectedOrigin != "o" || validationSearchDestiny
                          ? ""
                          : "var(--redPrimary)"
                      }
                    />

                    {focusOrigin == true ? (
                      <DesinitySearcher
                        type={desinitySearcherTypes.origin}
                        change={getSelectedOrigin}
                        inputFocused={isCardRefOrigin}
                        setFocus={setFocusOrigin}
                      />
                    ) : selectedOrigin === selectedDestination ? (
                      <TextComponent
                        className={"value-origin-destiny"}
                        color={"var(--redPrimary)"}
                        children={ellipsisText("Cambia tu origen", 17)}
                        size={"md2"}
                        fontWeight={"bold"}
                        onclick={{
                          voidP: (pa) => getSelectedOrigin(pa),
                          parameters: "o",
                        }}
                      />
                    ) : (
                      <p
                        style={
                          selectedOrigin != "o" || validationSearchDestiny
                            ? { color: "" }
                            : {
                                color: "var(--greySecundary)",
                                fontWeight: "400",
                              }
                        }
                        className="value-origin-destiny"
                        onClick={() => {
                          setFocusOrigin(true);
                        }}
                      >
                        {selectedOrigin === "o"
                          ? "Selecciona un origen"
                          : selectedOrigin}
                      </p>
                    )}
                  </div>
                  {focusOrigin === true ? (
                    ""
                  ) : (
                    <div>
                      <IoLocationOutline color="var(--bluePrimary)" size={30} />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="input-destiny-origin"
                style={{
                  border:
                    selectedDestination != "d" || validationSearchDestiny
                      ? "var(--greyPrimary) solid 1px"
                      : "var(--redPrimary) solid 1px",
                }}
              >
                <div className="content-inputs">
                  <div className="first-section">
                    <TextComponent
                      className={"label-destiny"}
                      children={t("fligth.search_fligth.destination")}
                      size={"md2"}
                      fontWeight={
                        selectedDestination != "d" || validationSearchDestiny
                          ? "light"
                          : "400"
                      }
                      color={
                        selectedDestination != "d" || validationSearchDestiny
                          ? ""
                          : "var(--redPrimary)"
                      }
                    />
                    {focusDestination ? (
                      <DesinitySearcher
                        type={desinitySearcherTypes.destination}
                        change={getSelectedDestination}
                        inputFocused={isCardRefDestination}
                        setFocus={setFocusDestination}
                      />
                    ) : selectedOrigin === selectedDestination ? (
                      <TextComponent
                        className={"value-origin-destiny"}
                        color={"var(--redPrimary)"}
                        children={ellipsisText("Cambia tu destino", 17)}
                        size={"md2"}
                        fontWeight={"bold"}
                        onclick={{
                          voidP: (pa) => getSelectedDestination(pa),
                          parameters: "d",
                        }}
                      />
                    ) : (
                      <p
                        style={
                          selectedDestination != "d" || validationSearchDestiny
                            ? { color: "" }
                            : {
                                color: "var(--greySecundary)",
                                fontWeight: "400",
                              }
                        }
                        className="value-origin-destiny"
                        onClick={() => {
                          setFocusDestination(true);
                        }}
                      >
                        {selectedDestination === "d"
                          ? "Selecciona un destino"
                          : selectedDestination}
                      </p>
                    )}
                  </div>
                  {focusDestination ? (
                    ""
                  ) : (
                    <div>
                      <IoLocationOutline color="var(--bluePrimary)" size={30} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="contend-inputs-date-passenger">
              <div
                className="inputs-triple"
                onClick={() => {
                  setCheckDate(false);
                  setIsCardRefDate(true);
                  setFlightDefault(false);
                }}
                style={{
                  display: isRoundTrip ? "none" : "flex",
                  border:
                    (dateGoingSelected === "") & validationSearchDate
                      ? "var(--redPrimary) solid 1px"
                      : "var(--greyPrimary) solid 1px",
                }}
              >
                <div className="contain-label-date">
                  <TextComponent
                    className={"label-destiny"}
                    children={t("fligth.search_fligth.date")}
                    size={"md2"}
                    fontWeight={
                      (dateGoingSelected === "") & validationSearchDate
                        ? "500"
                        : "light"
                    }
                    color={
                      (dateGoingSelected === "") & validationSearchDate
                        ? "var(--redPrimary)"
                        : ""
                    }
                  />
                  <TextComponent 
                    className={"select-destiny-input"} 
                    children={dateGoingSelected === "" 
                      ? t("fligth.search_fligth.going") 
                      : formatDate(new Date(dateGoingSelected))
                    } 
                    size={"md2"} 
                    color={
                      (dateGoingSelected === "") & validationSearchDate
                        ? "var(--greySecundary)"
                        : ""
                    }
                    fontWeight={
                      (dateGoingSelected === "") & validationSearchDate
                        ? "500"
                        : "semiNormal"
                    }
                    
                  />
                </div>
                <SvgIcon
                  name={iconList.IconCalendar}
                  color={"var(--bluePrimary)"}
                  size={30}
                />
              </div>

              <div
                className="inputs-triple"
                onClick={() => {
                  if (isRoundTrip) {
                    setCheckDate(true);
                    setIsCardRefDate(true);
                    setFlightDefault(false);
                  }
                }}
                style={{
                  display: !isRoundTrip ? "none" : "flex",
                  border:
                    (dateGoingSelected === "") & validationSearchDate
                      ? "var(--redPrimary) solid 1px"
                      : "var(--greyPrimary) solid 1px",
                }}
              >
                <div className="contain-label-date">
                  <TextComponent
                    className={"label-destiny"}
                    children={t("fligth.search_fligth.date")}
                    size={"md2"}
                    fontWeight={
                      (dateGoingSelected === "") &
                      (dateReturnSelected === "") &
                      validationSearchDate
                        ? "400"
                        : "light"
                    }
                    color={
                      (dateGoingSelected === "") &
                      (dateReturnSelected === "") &
                      validationSearchDate
                        ? "var(--redPrimary)"
                        : ""
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      color: "var(--bluePrimary)",
                      whiteSpace: "nowrap"
                    }}
                  >
                    <TextComponent
                      className={"select-destiny-input"}
                      children={
                        dateGoingSelected === ""
                          ? t("fligth.search_fligth.going")
                          : formatDate(new Date(dateGoingSelected))
                        }
                      size={"md2"}
                      fontWeight={
                        (dateGoingSelected === "") & validationSearchDate
                          ? "500"
                          : "semiNormal"
                      }
                      color={
                        (dateGoingSelected === "") & validationSearchDate
                          ? "var(--greySecundary)"
                          : ""
                      }
                    />
                    -
                    <TextComponent
                      className={"select-destiny-input"}
                      children={
                        dateReturnSelected === ""
                          ? t("fligth.search_fligth.return")
                          : formatDate(new Date(dateReturnSelected))
                      }
                      size={"md2"}
                      fontWeight={
                        (dateReturnSelected === "") & validationSearchDate
                          ? "400"
                          : "semiNormal"
                      }
                      color={
                        (dateReturnSelected === "") & validationSearchDate
                          ? "var(--greySecundary)"
                          : ""
                      }
                    />
                  </div>
                </div>
                <SvgIcon
                  name={iconList.IconCalendar}
                  color={"var(--bluePrimary)"}
                  size={30}
                />
              </div>

              {isCardRefDate && (
                <TooltipContainer
                  show={isCardRefDate}
                  hidden={setIsCardRefDate}
                  sizeWidth={100}
                  sizeHeight={80}
                >
                  <div className="searchFlightsContainerDate">
                    <FlightsDateGoing
                      oneWay={!isRoundTrip}
                      setOneWay={setIsRoundTrip}
                      roundTrip={isRoundTrip}
                      setRoundTrip={setIsRoundTrip}
                      setDateGoing={setIsCardRefDate}
                      dateGoing={isCardRefDate}
                      cardRef={cardRefDate}
                      setDateGoingSelected={setDateGoingSelected}
                      setDateGoingSelectedApi={setDateGoingSelectedApi}
                      setDateSreturnSelected={setDateReturnSelected}
                      setDateSreturnSelectedApi={setDateReturnSelectedApi}
                      searcherFlight={searcherFlight}
                      checkDate={checkDate}
                      setCheckDate={setCheckDate}
                      dateGoingSelected={dateGoingSelected}
                      dateSreturnSelected={dateReturnSelected}
                      accompanied={accompanied}
                      setDateOneWay={setDateOneWay}
                      setDateReturn={setDateReturn}
                      dateOneWay={dateOneWay}
                      dateReturn={dateReturn}
                      setIsManagingPassengers={setIsManagingPassengers}
                      flightDefault={flightDefault}
                    />
                  </div>
                </TooltipContainer>
              )}

              <div
                className="inputs-triple"
                onClick={() => setIsManagingPassengers(true)}
              >
                <div className="contain-label-date">
                  <TextComponent
                    className={"label-destiny"}
                    children={t("fligth.search_fligth.passengers")}
                    size={"md2"}
                    fontWeight={"light"}
                  />
                  <TextComponent
                    className={"select-destiny-input"}
                    children={
                      passengersSelecteds.adult +
                      passengersSelecteds.baby +
                      passengersSelecteds.boy +
                      passengersSelecteds.seat +
                      " " +
                      t("fligth.search_fligth.passengers")
                    }
                    size={"md2"}
                    fontWeight={"semiNormal"}
                  />
                </div>
                <SvgIcon
                  color={"var(--bluePrimary)"}
                  name={iconList.IconTwoPerson}
                  size={30}
                />

                {isManagingPassengers && (
                  <TooltipContainer
                    show={isManagingPassengers}
                    hidden={setIsManagingPassengers}
                    sizeWidth={100}
                    sizeHeight={40}
                  >
                    <FlightPassengerManagement
                      value={passengersSelecteds}
                      onChange={getPassengersSelecteds}
                      setIsManagingPassengers={setIsManagingPassengers}
                      refPass={cardRefPassenger}
                      isManagingPassengers={isManagingPassengers}
                      setPass={setPass}
                    />
                  </TooltipContainer>
                )}
              </div>
            </div>
          </div>
          {pathname === "/packages" ? (
            ""
          ) : (
            <button
              disabled={buttonDisable}
              className="btn-search-flight-horizontal"
              onClick={() => {
                searcherFlight();
              }}
            >
              <IoSearchOutline size={40} />
              {t("browse_home.search")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchFlights;
