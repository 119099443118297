import { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { data } from 'jquery';

function useUpsellChoose(
  getandOpen,
  closeGetandOpen,
  isRoundTrip,
  outwardJourney,
  returnJourney,
  recommendation,
  setDataCabin,
  setDataComponents,
  allPassengersSelected,
  setPriceUpsell,
  currency,
  continueBuy
) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [openUpsell, setOpenUpsell] = useState(false);
  const [upsell, setUpsell] = useState([]);
  const [farePrice, setFarePrice] = useState(0);
  const [typeOfCoin, setTypeOfCoin] = useState({ name: currency, abbreviation: currency, simbol: '$' });
  const [fare, setFare] = useState('RP');
  const [bag, setBag] = useState(false);
  const typeList = { INC: 'INC', NOF: 'NOF', CHA: 'CHA', }
  const [familiesCombinationsCode, setFamiliesCombinationsCode] = useState([]);
  const [selectedFare, setSelectedFare] = useState(null);
  const [selectedFareModal, setSelectedFareModal] = useState(null)
  const [openModal, setOpenModal] = useState(false);
  const [t] = useTranslation("global")
  const [fareSave, setFareSave] = useState([])
  const [priceUppsell, setPriceUppsell] = useState({
    fareAmount: 0,
    taxAmount: 0,
    totalAmount: 0,
  })


  function chooseFare(type, code,data = false) {
    const position = type === t("fligth.search_fligth.going") ? 0 : 1;
    const currenteFare = data ? data : selectedFare;
    const currenteFareCodeOneWay = currenteFare?.components[0]?.fareFamilyDetails?.fareFamilyname;
    const currenteFareCodeRoundTrip = currenteFare?.components[1]?.fareFamilyDetails?.fareFamilyname;
    if (position === 0) {
      const newFareSalected = upsell.find(
        (obj) => obj[0].components[0]?.fareFamilyDetails?.fareFamilyname === code
          && obj[0].components[1]?.fareFamilyDetails?.fareFamilyname === currenteFareCodeRoundTrip
      )
      const response = newFareSalected?.[0];
      setSelectedFare(response)
    }
    if (position === 1) {
      const newFareSalected = upsell.find(
        (obj) => obj[0].components[0]?.fareFamilyDetails?.fareFamilyname === currenteFareCodeOneWay
          && obj[0].components[1]?.fareFamilyDetails?.fareFamilyname === code
      )
      const response = newFareSalected?.[0];
      setSelectedFare(response)
    }
  }



  



  const textToLowerCase = (text) => {
    return text;
    // return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }



  function showDetails(data, _type) {
    const cleanData = [];
    const position = _type === t("fligth.search_fligth.going") ? 0 : 1;

    const allServices = data?.components[position]?.details?.ocFeeInformation;
    for (const s in allServices) {
      const service = allServices[s];
      const newObj = {
        status: service?.feeDescription?.dataInformation?.indicator,
        description: service?.feeFreeFlowDescription?.freeText,
        type: service?.serviceDetails?.specialRequirementsInfo?.serviceType,
      }
      cleanData.push(newObj)
    }
    setSelectedFareModal(cleanData);
    setOpenModal(true);
  }




  function closeModal() {
    setOpenModal(false);
    setSelectedFareModal(null)
  }


  function cleanData(data, type) {
    const position = type === 'ida' ? 0 : 1;
    const allServices = data?.components[position]?.details?.ocFeeInformation;
    const bagCode = "BG";
    const cleanData = [];
    const servicesIncluded = [];
    const servicesNotIncluded = [];
    const servicesExtraPaid = [];
    for (const s in allServices) {
      const service = allServices[s];
      const newObj = {
        status: service.feeDescription?.dataInformation?.indicator,
        description: service.feeFreeFlowDescription?.freeText,
        type: service.serviceDetails?.specialRequirementsInfo?.serviceType,
      }
      cleanData.push(newObj)
    }
    const servicesLuggage = cleanData.filter(obj => obj.type === bagCode
      //&& !obj.description.includes(carryOnHandBaggage)
    )
    const otherServices = cleanData.filter(obj => obj.type !== bagCode || (obj.type === bagCode
      // && obj.description.includes(carryOnHandBaggage)
    ));
    for (const o in otherServices) {
      const otherService = otherServices[o]
      if (otherService.status === typeList.INC) { servicesIncluded.push(otherService) }
      if (otherService.status === typeList.NOF) { servicesNotIncluded.push(otherService) }
      if (otherService.status === typeList.CHA) { servicesExtraPaid.push(otherService) }
    }
    return {
      servicesLuggage,
      servicesIncluded,
      servicesNotIncluded,
      servicesExtraPaid,
    }
  }




  function searchForCheapestFares(allcombinations) {
    const mostEconomical = allcombinations.reduce((prev, current) => {
      return (prev.priceTotalFare < current.priceTotalFare) ? prev : current;
    });
    return mostEconomical;
  }


  const extractData = () => {
    const combinations = [];
    for (const familia in upsell) {
      const passengers = upsell[familia]
      const passengersList = []

      for (const p in passengers) {
        const typePassenger = {
          count: passengers[p]?.pax,
          type: passengers[p]?.type[0]?.fareBasisDetails?.discTktDesignator,
          taxes: passengers[p]?.taxes,
          fare: passengers[p]?.fares?.fare,
          whitoutTax: passengers[p]?.fares?.whitoutTax,
          totalFare: passengers[p]?.fares?.fare * passengers[p]?.pax,
          totalWhitoutTax: passengers[p]?.fares?.whitoutTax * passengers[p]?.pax,
        }
        passengersList.push(typePassenger);
      }
      const combination = {
        code: familia,
        oneWay: upsell[familia][0]?.components[0]?.fareFamilyDetails?.fareFamilyname,
        passengers: passengersList,
        components: upsell[familia][0]?.components,
        details: upsell[familia][0]?.components[0]?.details,
        bag: upsell[familia][0].bag,
        priceTotalFare: passengersList.reduce((acumulador, valorActual) => { const subtotal = valorActual.count * valorActual.fare; return acumulador + subtotal; }, 0),
        priceTotalWhitoutTax: passengersList.reduce((acumulador, valorActual) => { const subtotal = valorActual.count * valorActual.whitoutTax; return acumulador + subtotal; }, 0)
      };
      if (isRoundTrip) {
        combination.roundtrip = upsell[familia][0]?.components[1]?.fareFamilyDetails?.fareFamilyname;
        combination.details2 = upsell[familia][0]?.components[1]?.details;
      }
      combinations.push(combination);
    }


    setFamiliesCombinationsCode(combinations)
    const mostEconomical = searchForCheapestFares(combinations)

    for (const i in upsell) {
      if (i === mostEconomical.code) {
        const familieObjComplete = upsell[i][0]
        setSelectedFare(familieObjComplete)
        break;
      }
    }

  }


  const currencyFormat = (numero) => {
    const numeroFormateado = numero //?.toLocaleString("en");
    return `${typeOfCoin.abbreviation} ${numeroFormateado}`;
  }

  function clearError() {
    setOpenUpsell(false)
    setIsEmpty(false)
  }


  function createSegment(listSegements, data, fligthType) {
    const classFlight = recommendation?.paxFareProduct[0]?.fareDetails[fligthType]?.groupOfFares[0]?.productInformation?.cabinProduct?.rbd;
    const obj = {
      "group": listSegements.length + 1,
      "from": data?.location[0].locationId,
      "to": data?.location[1].locationId,
      "company": data?.companyId?.marketingCarrier,
      "companyName": data?.companyName,
      "flightNumber": parseInt(data?.flightOrtrainNumber),
      "class": classFlight,
      "hour": `${data?.productDateTime?.dateOfDeparture} ${data?.productDateTime?.timeOfDeparture}:00`,
    }
    return obj;
  }

  function getSegments() {
    let totalListSegments = [];
    const outwardSegments = outwardJourney?.segments;
    const returnSegments = returnJourney?.segments;
    const allSegments = [outwardSegments];
    if (isRoundTrip) {
      allSegments.push(returnSegments)
    }
    for (const i in allSegments) {
      const groupSegments = allSegments[i];
      for (const g in groupSegments) {
        const data = groupSegments[g]
        const segmentCreated = createSegment(totalListSegments, data, i);
        totalListSegments.push(segmentCreated);
      }
    }
    return totalListSegments;
  }




  async function getUpsell() {
    if (isLoading) { return; }
    setIsLoading(true);
    try {
      const dataToSend = {
        "adult": allPassengersSelected?.adult,
        "baby": allPassengersSelected?.baby,
        "child": allPassengersSelected?.boy,
        "seat":allPassengersSelected?.seat,
        "fare": fare,
        "bag": bag,
        "rountrip": isRoundTrip === "false" ? false : true,
        "currency": currency,
        "segments": getSegments(),
      }
      const url = `${process.env.REACT_APP_URL_API_FLY_NEW}upsell`;
      const result = await axios.post(url, dataToSend);
      if(isRoundTrip === "false"){
        if (result && result?.data?.status === 200) {
        console.log(result?.data)
        setUpsell(result?.data?.families)
        if (result?.data?.families.length === 0) {
          console.log(result?.data)
          setIsEmpty(true)
        }

        return;
      }
      }else if(result && result?.data?.["0"]?.status === "OK"){
        console.log(result?.data)
        setUpsell(result?.data?.families)
        if (result?.data?.families.length === 0) {
          console.log(result?.data)
          setIsEmpty(true)
        }

        return;
      }
      setIsEmpty(true)
    } catch (error) {
      console.log("Error in getUpsell: ", error)
      setIsEmpty(true)
    }
    finally {
      setIsLoading(false)
      setOpenUpsell(true)
      if (getandOpen) { closeGetandOpen(); }
    }
  }



  useEffect(() => {
    if (upsell && upsell.length > 0) {
      extractData();
    }
  }, [upsell])



  useEffect(() => {
    const body = document.querySelector('body');
    openModal ? body.style.overflow = 'hidden' : body.style.overflow = '';
  }, [openModal])


  const savePrice = (price, type) => {

    const dataPrice = {
      fareAmount: Number(price?.priceTotalWhitoutTax) ,
      taxAmount: Number(price?.priceTotalFare - price?.priceTotalWhitoutTax),
      totalAmount: price?.priceTotalFare ,
      passengers: price?.passengers
    };

    if (type) {
      continueBuy(true, {fareAmount: dataPrice.fareAmount, taxAmount:  + dataPrice.taxAmount, totalAmount:dataPrice.totalAmount, passengers: dataPrice?.passengers
      })
    }
  };



  useEffect(() => {
    if (selectedFare) {
      setDataCabin(selectedFare?.cabin)
      setDataComponents(selectedFare?.components)
      const proceCombinations = familiesCombinationsCode.find((obj) =>
        obj.oneWay == selectedFare?.components[0]?.fareFamilyDetails?.fareFamilyname &&
        obj.roundtrip == selectedFare?.components[1]?.fareFamilyDetails?.fareFamilyname
      );
      const newPrice = proceCombinations?.priceTotalFare
     
      setFarePrice(proceCombinations);
    }

  }, [selectedFare])



  useEffect(() => {
    if (getandOpen) {
      getUpsell()
    };
  }, [getandOpen])

  return {
    isLoading,
    isEmpty,
    openUpsell,
    setOpenUpsell,
    getUpsell,
    clearError,
    currencyFormat,
    farePrice,
    selectedFare,
    cleanData,
    setFarePrice,
    setPriceUppsell,
    familiesCombinationsCode,
    chooseFare,
    showDetails,
    textToLowerCase,
    typeList,
    selectedFareModal,
    openModal,
    closeModal,
    upsell,
    savePrice
  }
}



export default useUpsellChoose;
